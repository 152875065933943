import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Subscription } from 'rxjs';
IntervalObservable

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.css']
})
export class AdvertiseComponent implements OnInit, OnDestroy {

@Input() switchTime: number;
slides = Array<Object>()
index: number = 0;
timer;
timerSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    // var ad1 = {} as advertisement
    var ad2 = {} as advertisement
    var ad3 = {} as advertisement

    var ad1 = {
    image: '../../assets/Images/bsgLanguage.jpg',
    // image: '../../../assets/Images/cover1.png',
    link: 'https://itiswritten.shop/product-category/bible-study-guides/sets/'
    }
    
    ad2.image = '../../../assets/Images/mpwj.jpg'
    ad2.link = 'https://itiswritten.shop/product/my-place-with-jesus-bible-guide-set/'
    ad2.state = 'void'

    ad3.image = '../../../assets/Images/support.jpg'
    ad3.link = 'https://www1.itiswritten.com/give/'
    ad3.state = 'void'

    this.slides.push(ad1)
    this.slides.push(ad2)
    this.slides.push(ad3)

    var time = this.switchTime || 6500
    this.timer = IntervalObservable.create(time)
    this.timerSubscription = this.timer.subscribe(() =>  this.next() )
  }
  ngOnDestroy(){
    if(this.timerSubscription){
      this.timerSubscription.unsubscribe()
      this.timer = null
    }
  }


  next(){
    
    // console.log(`switch to slide ${this.index}`)

    

    if (this.index < this.slides.length -1){
      // this.enable(this.slides[this.index + 1])
      // this.disable(this.slides[this.index])
       this.index += 1
      } 
    else if ( this.index >= this.slides.length - 1){ 
      // this.enable(this.slides[0])
      // this.disable(this.slides[this.index])
      this.index = 0
    }

    
  }

  disable(slide: advertisement){
    slide.state = 'void'
  }
  enable(slide: advertisement){
    slide.state = 'show'
  }





}


export interface advertisement{
  image: string;
  link: string;
  state: string;
}