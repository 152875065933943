import { Injectable } from '@angular/core';

@Injectable()
export class GlobalVariablesService {
  debug = false;
  constructor() { }

  print(...something){
    if (this.debug) {console.log(...something)}
  }
}

