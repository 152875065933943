
<div class="background">
  <div class="inset" [@trigger]=currentState >
    <h1>Contact Us</h1>
    <p>Our regular business hours are Monday–Thursday, 8:00 AM – 6:00 PM Eastern Standard Time. Please allow extra time for responses to messages sent to us over the weekend.
      <br><br>By Phone: <span class="BoldText">+1 844-974-8836   (844-WRITTEN)</span>
      <br><br>Or send a message using the form below.
     </p>
    <div class="horizontalLine "></div>
    <p>This is a:</p>
     <form (ngSubmit)="submitMessage()">
        <input type="checkbox" id="c1" name="cc" [(ngModel)]="bibleQuestion" />
        <label for="c1"><span></span>Bible Question or Comment</label><br>
        
        <input type="checkbox" id="c2" name="cc" [(ngModel)]="supportQuestion" />
        <label for="c2"><span></span>Technical Support Question or Comment</label><br>
        
        <input type="checkbox" id="c3" name="cc" [(ngModel)]="other" /> 
        <label for="c3"><span></span>Other</label><br>
        


        <input id="name" class="short" name="name" type="text" placeholder="Your Name" [(ngModel)]="name"><br>
        <input id="email" class="short" name="email" type="email" placeholder="Your Email Address" [(ngModel)]="email"><br>
        <textarea id="message" class="message" name="moreinfo" cols="60" rows="10" placeholder="Your Message" required="required" [(ngModel)]="message">
          </textarea><br>
          <div class="alert hideMessage" [ngClass]="{showMessage : verificationMessage, hideMessage:!verificationMessage}">
              <span class="closebtn" (click)="hideSuccessMessage()">&times;</span> 
              <strong>Message Sent!</strong> We'll get back to you!
            </div>
          <div class="errorAlert hideMessage" [ngClass]="{showMessage : errorMessage, hideMessage:!errorMessage}">
              <span class="closebtn" (click)="hideErrorMessage()">&times;</span> 
              <strong>{{errorMessage}}</strong> 
            </div>

        <button class="buttonConfig">Send</button>
        <div style="width: 100%; height: 0; padding-bottom: 50%"></div>
  </form>
  </div>
</div>