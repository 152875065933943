<div class=container>
    <img src="../../../assets/Images/girlWithBible.png">
  <div class="text">
    <h2>Let's Study the Bible!</h2>
    <p>The My Place With Jesus Bible Guides are the perfect way to learn God’s plan for you! 
      These FREE guides will guide you step-by-step into a better understanding of the Bible. Along the way you’ll enjoy fun puzzles and activities!<br>
      There are 21 guides. They cover the major themes of the Bible using simple questions that point you straight to what the Bible says.
    </p>
    <div class=startButton (click)="start()">I'm ready to start</div>
  </div>
  
</div>