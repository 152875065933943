
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent, SafePipe, RelativeDatePipe, FirstLetterPipe, NewlinePipe, OtherLettersPipe, CapatalizePipe } from './app.component';
import { FooterSectionComponent } from './components/footer-section/footer-section.component';
import { LoginSignUpComponent } from './components/login-sign-up/login-sign-up.component';

import { HomeComponent } from './pages/home/home.component';
import { LessonTemplateComponent } from './templates/lesson-template/lesson-template.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { LessonsInformationComponent } from './pages/lessons-information/lessons-information.component';
import { LessonHeaderComponent } from './pages/lesson-header/lesson-header.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { LessonsFlatComponent } from './components/lessons-flat/lessons-flat.component';

import { environment } from '../environments/environment';

import { Routes, RouterModule, Router} from '@angular/router';
import {enableProdMode} from '@angular/core';
enableProdMode()

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { QuestionTemplateComponent } from './templates/question-template/question-template.component';
import { UserManager } from './Services/UserManager/user-manager';
import { LessonManagerService } from './Services/LessonManager/lesson-manager.service';
import { GlobalVariablesService } from './Services/globalVariables/global-variables.service';
import { MyLessonsComponent } from './pages/my-lessons/my-lessons.component';
import { ScrollService } from './Services/ScrollManager/scroll.service';
import { FinishedComponent } from './pages/finished/finished.component';
import { LessonBlocksComponent } from './components/lesson-blocks/lesson-blocks.component';
import { LessonHeaderBarComponent } from './components/lesson-header-bar/lesson-header-bar.component';
import { AdvertiseComponent } from './components/advertise/advertise.component';

import { TourComponent } from './pages/tour/tour.component';
import { BrowserCheckerComponent } from './components/browser-checker/browser-checker.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MainTopComponent } from './components/main-top/main-top.component';
import { StartStudyComponent } from './components/start-study/start-study.component';
import { CrossWordComponent } from './components/cross-word/cross-word.component';
import { CrosswordTopBackgroundComponent } from './components/crossword-top-background/crossword-top-background.component';
import { CrosswordBottomBackgroundComponent } from './components/crossword-bottom-background/crossword-bottom-background.component';
import { CrosswordPageComponent } from './pages/crossword-page/crossword-page.component';
import { GamesPageComponent } from './pages/games-page/games-page.component';
import { InstructionComponent } from './components/instruction/instruction.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { BypassComponent } from './components/bypass/bypass.component';
import { FinishedLessonComponent } from './components/finished-lesson/finished-lesson.component';


export const router: Routes = [
  { path: '', redirectTo: 'home' , pathMatch: 'full'},
  { path: 'lessons-information', component: LessonsInformationComponent},
  { path: 'lesson/:id', component: BypassComponent},
  { path: 'crossword', component: CrosswordPageComponent},
  { path: 'lesson-template', component: LessonTemplateComponent},
  { path: 'lesson-header', component: LessonHeaderComponent},
  { path: 'lesson-completed', component: FinishedLessonComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'my-lessons', component: MyLessonsComponent},
  { path: 'finished', component: FinishedComponent},
  { path: 'tour', component: TourComponent},
  { path: 'games', component: GamesPageComponent},
  { path: '**', redirectTo: 'my-lessons' }
];



@NgModule({
  declarations: [
    AppComponent,
    FooterSectionComponent,
    LoginSignUpComponent,
    LessonTemplateComponent,
    FinishedLessonComponent,
    HomeComponent,
    NavigationBarComponent,
    LessonsFlatComponent,
    ContactUsComponent,
    LessonsInformationComponent,
    LessonHeaderComponent,
    InstructionComponent,
    QuestionTemplateComponent,
    MyLessonsComponent,
    FinishedComponent,
    LessonBlocksComponent,
    LessonBlocksComponent,
    LessonHeaderBarComponent,
    SafePipe,
    RelativeDatePipe,
    FirstLetterPipe,
    OtherLettersPipe,
    CapatalizePipe,
    NewlinePipe,
    AdvertiseComponent,
    TourComponent,
    BrowserCheckerComponent,
    ProfileComponent,
    MainTopComponent,
    StartStudyComponent,
    CrossWordComponent,
    CrosswordTopBackgroundComponent,
    CrosswordBottomBackgroundComponent,
    CrosswordPageComponent,
    GamesPageComponent,
    BypassComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(router),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    NgxPageScrollCoreModule,
    DragDropModule


  ],
  providers: [ 
    UserManager,
    LessonManagerService,
    GlobalVariablesService,
    NgxPageScrollModule,
    ScrollService,
    AngularFireAuthModule],
  bootstrap: [AppComponent],
  schemas :[ NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
  
 }
