<div class="background">
    <div class="inset">
      <div >
        <h1 class="">Congratulations!</h1>
        <div class="horizontalLine"></div>
        <p class="">You've completed the online My Place with Jesus Study Guides!  We're glad you made it to the end!   Feel free to leave us a comment or two on the "Contact Us" page.</p>
        <div class="center">
          <button  class="buttonConfig transition" (click)="gotoMyLessons()" >My Study Guides</button>
          <button  class="buttonConfig transition" (click)="gotoContactUs()" >Contact Us</button>
      </div>
      </div>
    </div>
  </div>