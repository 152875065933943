import { Component, OnInit , EventEmitter, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { Parent } from './../../Models/user.interface';
import { LessonIntro } from '../../Models/lessonIntro.interface';
import {UserManager} from '../../Services/UserManager/user-manager'
import 'rxjs/add/operator/take'
import { Subject } from 'rxjs/Subject'
import { GlobalVariablesService } from '../../Services/globalVariables/global-variables.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import { trigger, state, style, transition, animate} from '@angular/animations'
import { LessonCard } from '../../Models/lessons.interface';
import { LessonManagerService } from '../../Services/LessonManager/lesson-manager.service';
import { DomSanitizer} from '@angular/platform-browser';
import { Location } from '@angular/common';



@Component({
  selector: 'app-lesson-header',
  templateUrl: './lesson-header.component.html',
  styleUrls: ['./lesson-header.component.css'],
  animations: [
    trigger('all', [
        state('show', style({
            opacity: 1           
        })),
        state('void', style({
          opacity: 0           
      })),
        transition('void => show',[ style({opacity: 0}),
            animate('400ms 400ms ease-out')]),
          transition('show => *',[ style({opacity: 1}),
            animate('200ms 200ms ease-out')]),
    ]),
    
  ]
})
export class LessonHeaderComponent implements OnInit, OnDestroy {
  id: string = "1"
  title: string;
  img: string;
  lessonCard: LessonCard;
  intro: LessonIntro;
  welcomeMessage: string;
  unlockedObservable: Observable<Object>;
  unlocked: boolean = false;
  unlockSubscription: Subscription;
  previousUnlocked: number = 1;
  profile: Parent;
  profileSubscription: Subscription;
  anonymous;
  done = new EventEmitter();
  safeVideo = {};
  video;
  leaving = new Subject<null>()
  currentState = 'void';
  loaded = false;
  constructor( private route: ActivatedRoute,
              private router: Router,
              private db: AngularFireDatabase,
              public um: UserManager,
              private gv: GlobalVariablesService,
              private scroll: ScrollService,
              private lm: LessonManagerService,
              private securityOverride: DomSanitizer,
              private lc: Location,
            ) {

  }

  ngOnInit() {
    this.um.getProfileSubject()
    .takeUntil(this.leaving)
    .subscribe(profile => {
      this.profile = profile
      if(!this.loaded){this.load()}
      
      if(this.profile){
        this.anonymous = profile.anonymous;
        this.load()
        if ( this.id === "1"){
          this.scroll.scrollToElement('#lesson1Heading')
        } else {
          this.scroll.scrollToElement('#LessonIntro')
        }
      }
  
    })
    
    // this.db.object(`Videos`).valueChanges().take(1).subscribe(video => {this.video = video[`Lesson${this.id}`];})
    

  }

  load(){
    this.loaded = true
    this.id = this.route.snapshot.paramMap.get('id');
    this.title = this.route.snapshot.paramMap.get('title');
    this.img = this.route.snapshot.paramMap.get('img');
    this.lc.replaceState(`/lesson-header;id=${this.id}`)
    if (!this.id){
      this.lessonCard = this.lm.getCard(1)
    } else {
      this.lessonCard = this.lm.getCard(+this.id)
    }
    this.gv.print(`id = ${this.id}, title = ${this.title}, img string = ${this.img}`);

    this.unlocked = this.id === "1" ? true : false
    this.db.object<LessonIntro>(`Questions/${this.id}/${this.id}-0`).valueChanges().take(1).subscribe(intro => {
      if(intro){
        this.intro = intro
        this.currentState = 'show'
        
      }
    }); 

    this.gv.print(`id = ${this.id}`)
    if (this.id === "1"){
       this.db.object<string>(`messages/lessonOneWelcome`).valueChanges().take(1).subscribe(message => {
       if(message){
         this.gv.print(message)
          this.welcomeMessage = message
        }
      });
    } 

    this.unlockedObservable = this.um.getUnlockObjectSubject()
    this.unlockSubscription = this.unlockedObservable.subscribe(unlockObject => {
      // console.log(this.unlockedObservable)
      this.um.getPreviousUnlocked(+this.id).then(pu => {this.previousUnlocked = pu; })
      if (unlockObject[this.id]){
         this.unlocked = unlockObject[this.id]
      }
        
      })


  }

  ngOnDestroy(){
    this.leaving.next(null)
    if(this.unlockSubscription){this.unlockSubscription.unsubscribe()}
  }

  gotoLesson() {
    if(this.profile){
      
    }
    this.done.take(1).subscribe(_ =>  this.um.gotoLesson(+this.id))
    this.currentState = 'void'
  }

  gotoLessonHeader(lesson: number){
    this.done.take(1).subscribe(_ => this.um.gotoLessonHeader(lesson).then(_ => this.load()))
    this.currentState = 'void'
   
  }
  next(e){
    if (e.phaseName === 'done' && e.toState ==='void'){
      // console.log('emitting')
      this.done.emit(null)
    }
  }

}
