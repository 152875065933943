
<!-- 
<app-test-svg></app-test-svg> -->
<div [@trigger]=currentState>
    <app-browser-checker></app-browser-checker>
    <app-main-top></app-main-top>
    <app-start-study></app-start-study>
    <app-lessons-flat></app-lessons-flat>
</div>


