<!-- <div *ngIf="question?.image"  class=center>
  <img src={{question.image}} style="max-width: 600px; object-fit: cover;">
</div> -->


<!-- <h3 *ngIf="question?.questionType == 'truthConfirmation'" class="question">{{question?.body}}</h3> -->

<!-- <div style="min-height: 50vh;"> -->
  <div id="SupplementalAnchor"></div>
<div [@questionTrig]=currentState  (@questionTrig.start)="openSupplementEnd($event)" cdkDropListGroup>
    <h2 *ngIf="question?.questionIDTitle && question?.questionType !== 'truthConfirmation'" class="accentText bold captionText question">{{question?.questionIDTitle}}. {{question?.question}}</h2>
    <h2 *ngIf="!question?.questionIDTitle && question?.questionType !== 'truthConfirmation'" class="accentText bold captionText question">{{question?.questionID}}. {{question?.question}}</h2>
    <h3 *ngIf="question?.questionType === 'truthConfirmation'" class="darkGreyText bold captionText question">{{question?.question}}</h3>
    <h2 *ngIf="question?.subQuestion" class="darkGreyText regularText question" [innerHtml]="question?.subQuestion | newline" ></h2>

    <!-- All Correct SVG -->
    <div [ngClass]="{'correctFlex': allCorrect, 'incorrectFlex': !allCorrect}">
        <div 
          *ngIf="allCorrect"
          style="width: 10%; margin: 10px auto 10px auto">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
        </div>
    </div>

    <!-- Instructions Section -->
    <p *ngIf="question?.questionType === 'wordCloud'" class="instructions greyText regularText instruction_container ">
        Click or tap the correct answer to select it, 
        then click/tap the appropriate blank. 
        To remove an answer, double tap it to return it to the word cloud.  
    </p>

    <p *ngIf="question?.questionType === 'checkbox'" class="instructions greyText regularText instruction_container">
        Select all that apply.
      </p>
      
    <p *ngIf="question?.questionType === 'radio'" class="instructions greyText regularText instruction_container">
      Select the correct answer.
    </p>


    <!-- Verse Prompts -->
    <div 
      *ngIf="question?.questionType != 'trueOrFalse' && question?.questionType !== 'truthConfirmation' && question?.questionType !== 'text'" 
      class="content_container"
    >
      <div class="spanwrapper" *ngIf="vpShown">
        <span  *ngFor="let vp of versePrompts" > 
          <div *ngIf="vp?.verse" class="verseWrap">
              <br>
              <br>
              <div 
                class="verseLabel button3"
                (click)="selectVerse(vp.verse.ref, vp.verse.body)">
                  <h4><strong>{{vp?.verse?.ref}}</strong></h4>
              </div>
              
          </div>
             
          <span class="captionText">
            {{vp.textBefore}}
            <div 
              
              class="blank regularText regularBorder" 
              cdkDropList
              (cdkDropListDropped)="onDrop($event, versePromptIDs[vp.$key])"
              [cdkDropListData]="answersArray"
              (click)="selectBlank(vp)" 
            >
              <div 
                *ngIf="answers[vp.$key] && question?.questionType !== 'radio'"
                class=removeChoice 
                (click)="selectAnswer(answers[vp.$key], $event, true)"
              >
                &times;
              </div>
              <div 
                *ngIf="answers[vp.$key] && answers[vp.$key].choice && question?.questionType !== 'radio'"  
                (click)="closeModal($event)" 
                class="choicePadding" 
              >
                <div 
                  class="choiceLabel captionText"
                  [ngClass]="{correct:answers[vp.$key].correct, incorrect:!answers[vp.$key].correct}"
                  (click)="selectAnswer(answers[vp.$key], $event)"
                >
                  
                  {{answers[vp.$key]?.choice}}    
                </div>
              </div>
              <div *ngIf="question?.questionType === 'radio' && radioDisplayAnswer"
                  [ngClass]="{correct:answers[vp.$key]?.correct, incorrect:!answers[vp.$key]?.correct}" 
                  class="choicePadding" 
              >
                <label class="choiceLabel captionText " >{{radioDisplayAnswer}}</label>
              </div>
              </div>
          
            {{vp.textAfter}}
            </span>
                  <span *ngIf="vp.newline"><br *ngFor="let newline of vp.newline"></span>
              </span>
          </div>
    </div>

        <div *ngIf="question?.questionType === 'radio'" class="radioButtonContainer flexy">
            <div *ngFor="let c of question?.choices"  style="margin: 10px;">
              <label class="container">
                  <span class=" radioLabel captionText">{{c}}</span>
                    <input type="radio" name="radio" (click)="saveAnswerWithIndex(c)">
                    <span class="radiomark"></span>
                </label>
            </div>
          </div>

        <!-- True or False -->
        <div *ngIf="question?.questionType === 'trueOrFalse'">
            <div class="radioButtonContainer">
                <!-- <h3 style="text-align: center"><span class="captionText extraBold">True or False? </span><span class="bold regularText reallyDarkGreyText">{{question?.trueFalseQuestion}}</span></h3> -->
                <h3 style="text-align: center"><span class="bold regularText reallyDarkGreyText">{{question?.trueFalseQuestion}}</span></h3>
                <form class="flexy" >
                    <label class="container">
                        <span class="trueFalseLabel captionText">True</span>
                          <input type="radio" name="radio" (click)="saveTrueOrFalse('true')">
                          <span class="radiomark"></span>
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <label class="container">
                          <span class="trueFalseLabel captionText">False</span>
                            <input type="radio" name="radio" (click)="saveTrueOrFalse('false')">
                            <span class="radiomark"></span>
                      </label>
                </form>
            </div>
          </div>
        <div class="center">
          <div *ngIf="question?.verse" class="verseWrap">
            <div 
              class="verseLabel button3"
              (click)="selectVerse(question.verse.ref, question.verse.body)">
               <h4><strong>{{question?.verse?.ref}}</strong></h4>
            </div>
          </div>
      </div>

        <!-- text -->
        <div *ngIf="question?.questionType === 'text'">
            <!-- <div class="content_container" [innerHtml]="body | newline"> -->
              <div class="content_container">
              <h2 *ngFor="let b of question?.text" class="narrowText reallyDarkGreyText">{{b}}<br></h2>
            </div>
        </div>

        <!-- truthConfirmation -->
        <div *ngIf="question?.questionType === 'truthConfirmation'">
          <div class="radioButtonContainer">
              <!-- <h3 style="text-align: center"><span class="captionText extraBold">True or False? </span><span class="bold regularText reallyDarkGreyText">{{question?.trueFalseQuestion}}</span></h3> -->
              <form class="flexy" >



                  <label class="container">
                      <span class="radioLabel captionText">Yes!</span>
                        <input type="radio" name="radio" (click)="truthConfirmed('true')">
                        <span class="radiomark"></span>
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label class="container">
                        <span class="radioLabel captionText">No Thanks</span>
                          <input type="radio" name="radio" (click)="truthConfirmed('false')">
                          <span class="radiomark"></span>
                    </label>
                </form>
          </div>
        </div>
        <!-- Checkbox Answers -->
        <div *ngIf="question?.questionType === 'checkbox'">

        </div>


        

        <!-- Word cloud -->
        <!-- <div 
          [attr.id]="wordCloudContainer"
          *ngIf="question?.questionType === 'wordCloud'"
          style="touch-action: none;"
          class="choiceContainer" 
          [dragula]="wordBag"
          [dragulaModel]='question?.choices' 
          [dragulaOptions]="dragOptions">
              <div *ngFor="let c of question?.choices"  >
              <div *ngIf="shouldShowChoice(c)" class="choiceLabel captionText unselected" style="touch-action: none;" (click)="selectChoice(c, $event)" >{{c}}</div>
            </div>
        </div> -->

        <div 
          cdkDropList
          *ngIf="question.questionType === 'wordCloud'"
          style="touch-action: none;"
          class="choiceContainer" 
          cdkDropListOrientation="horizontal"
          [cdkDropListData]="question.choices"
          (cdkDropListDropped)="onDrop($event, 'wordCloud')"
        >
              <div  *ngFor="let c of question.choices"  >
              <div 
                cdkDrag
                [cdkDragData]="c"
                *ngIf="shouldShowChoice(c)" 
                class="choiceLabel captionText unselected" 
                style="touch-action: none;" 
                (click)="selectChoice(c, $event)" >{{c}}</div>
            </div>
        </div>
            





          <div [@item] [attr.id]="bodyID" *ngIf="allCorrect && question?.body && question.questionType !== 'truthConfirmation'" class="bodyContainer"><div class="body regularText fade show" [innerHtml]="question?.body | newline"></div></div>
          <!-- Supplement -->
          <!-- Supplement buttons -->
          
          <div *ngIf="supplements">
              <div class="supplementButtons" *ngFor="let supplement of supplements | async">
                <div class="supButton button3" (click)="openSupplement(supplement)">{{supplement?.title}}</div>
              </div>
           </div>
</div>         
 <div class="supContainer" [@supplementTrigger]=supplementState (@supplementTrigger.start)="closeSupplementEnd($event)">
  <!-- <div class="supContainer" [@supplementTrigger]=supplementState> -->
   <!-- <div *ngIf="supplementState === 'supplement'"> -->
    <div style="text-align:center">
      <div class="flexCol" >
        <h1 class="centerArrow button3" (click)="closeSupplement($event)" >&#8682;</h1>
        <h2  class="supButton button3" (click)="closeSupplement($event)"> Back</h2>
      </div>
    </div>
    <h1 class="supplementTitle">{{selectedSupplement?.title}}</h1>
    <p class="supplementBody" [innerHtml]="selectedSupplement?.body | newline"></p>
  <!-- </div> -->
</div>

<!-- </div> -->
<div id="verseModal" class="modal" [ngClass]="{modalExit:!showVerse, hide: !showVerse}" (click)="closeModal($event)"> <!-- old id = "myMoal" -->
  <!-- The Verse Modal -->
        <!-- Modal content -->
        
          <div class="modalContent" (click)="stopPropagation($event)">
            
              <div class="modal-header">
                  <span class="close" (click)="setVerseShown(false)">&times;</span>
                  <h2>{{selectedVerse?.ref}} NKVJ</h2>
              </div>
              <!-- <div class="modal-body" *ngFor="let v of question?.verses"> -->
                <!-- <h2>{{v.ref}}</h2> -->
                <!-- <p>{{v.body}}</p> -->
                <div class="modal-body">
                <p  *ngIf="selectedVerse" [innerHtml]="selectedVerse.body | newline"></p>
              </div>
              <!-- </div> -->
              <div class="modal-footer">
              <h3></h3>
              </div>
            
          </div>
      
  </div>
  <!-- Contact Component -->

    <!-- <div *ngIf="userQuestion==='show'" class="QuestionBackdrop" [@userQuestion]="userQuestion" >
      <form (ngSubmit)="submitStudentQuestion($event)"  class="QuestionBorder">
        <div class="btnctn"><span class="closebtn" (click)="closeStudentQuestion()">&times;</span></div> <br>
        <h1 class="regularText whiteText " style="display: block; text-align: center">Ask a question</h1>
        <h6 class="regularText whiteText userQuestionFormatter " style="display: block; text-align: left">Email</h6>
        <input id="questionTitleInput" class="short" type="email" name="email" placeholder="Your email" [(ngModel)]="questionUserEmail">
        <h6 class="regularText whiteText userQuestionFormatter" style="display: block; text-align: left">Question</h6>
        <textarea class="message" name="moreinfo" cols="60" rows="10" placeholder="Your Question" required="required" [(ngModel)]="questionMessage">
          </textarea><br>
        <button class="buttonConfig button" >Submit</button>
        <h3 class="regularText whiteText" style="text-align: center;" >{{questionError}}</h3>
      </form>
  </div> -->

<!-- Come to church prompt -->
<!-- <div *ngIf="userQuestion==='show' && questionID === 19 && lessonID === 8" class="QuestionBackdrop" [@userQuestion]="userQuestion" >
  <form (ngSubmit)="SendGoToChurchMessage($event)"  class="QuestionBorder">
    <div class="btnctn"><span class="closebtn" (click)="closeStudentQuestion()">&times;</span></div> <br>
    <h1 class="regularText whiteText " style="display: block; text-align: center">Find a church near me</h1>
    <h6 class="regularText whiteText userQuestionFormatter " style="display: block; text-align: left">Email</h6>
    <input id="questionTitleInput" class="short" type="email" name="email" placeholder="Your email" [(ngModel)]="questionUserEmail">
    <h6 class="regularText whiteText userQuestionFormatter" style="display: block; text-align: left">Question</h6>
    <textarea class="message" name="moreinfo" cols="60" rows="10" required="required" [(ngModel)]="churchMessage">
      </textarea><br>
    <button class="buttonConfig button" >Send</button>
    <h3 class="regularText whiteText" style="text-align: center;" >{{questionError}}</h3>
  </form>
</div> -->

