import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import { trigger, state, transition, animate, style} from '@angular/animations';
import { UserManager } from '../../Services/UserManager/user-manager';
@Component({
  selector: 'app-games-page',
  templateUrl: './games-page.component.html',
  styleUrls: ['./games-page.component.scss'],
  animations: [
    trigger('title', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('400ms ease-in-out')]),
        transition('* => void',[ animate('400ms ease-in-out')]),
  ])]
})
export class GamesPageComponent implements OnInit {
  games = []
  currentState = 'show'
  completed = {
    1: true
  }
  constructor(
    private scroll: ScrollService,
    private um: UserManager
    ) { 
    for(let i=1; i < 22; i++){
      this.games.push({
        name: '',
        index: i,
        img: `../../../assets/Images/Activity${i}.jpg`,
        activity: `../../../assets/Images/Activity${i}.pdf`,
        locked: false,
      })
    }
    this.um.getUnlockSubject().subscribe(lessons => {
      if(lessons){
        for (let lesson of lessons){
          if(!this.games[+lesson.id -1]){continue}
          if (!lesson.unlocked){ this.games[+lesson.id -1].locked=true }
          else if (lesson.unlocked){ this.games[+lesson.id -1].locked=false }         
      }
    }
  })
    

  }

  ngOnInit() {
    this.scroll.scrollToElement('#logo')
  }
  gotoGame(game) {
    if(game.locked){return}
    this.um.gotoGame(game.index)
  }

  

}
