
<div class="background" [@title]=currentState>
    <div class="inset" [@title]=currentState>
      <h1>Puzzles</h1>
      <p>Here are the puzzles you've unlocked.  Choose a puzzle to play!</p>
      <div class = "horizontalLine"></div>
    </div>
    <br><br>
    <div >

    <div class=flex>
      <div *ngFor="let game of games" class=game>
        <div *ngIf=game?.locked [ngClass]="{'locked':game?.locked}">
          <i class="fas fa-lock"></i>
        </div>
        <div *ngIf=!game?.locked class=options>
          <div *ngIf="completed[game?.index]" class=" a nbutton" (click)=gotoGame(game)><span class=a>Online</span></div>
          <a class="b nbutton"  href={{game.activity}} download="Activity{{game.index}}" ><span class=b>PDF</span></a>
        </div>
        <img src={{game.img}}/>
      </div>
    </div>
    </div>
  </div>