import { UserManager } from './../../Services/UserManager/user-manager';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Parent, Child} from '../../Models/user.interface'
import { trigger, state, transition, style, animate} from '@angular/animations'
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription, BehaviorSubject } from '../../../../node_modules/rxjs';


@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
    animations: [
    trigger('trigger', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('300ms ease-in-out')]),
        transition('* => void',[ animate('300ms ease-in-out')]),
  ])
  ]
})
export class NavigationBarComponent implements OnInit {
  profileSubject;
  parent: Parent;
  activeChild: Child;
  unread;
  Me: string = "My Guides"
  sub: Subscription;
  leaving = new BehaviorSubject<null>(null)

  constructor(private router: Router, public um: UserManager, private db: AngularFireDatabase) { }
  buttonTrigger = 'void'
  ngOnInit() {

    this.profileSubject = this.um.getProfileSubject()
    this.profileSubject.subscribe( profile => {
      if(profile){
        this.parent = profile as Parent
        if(!this.sub){
          this.sub = this.db.object(`UnreadMessages/${profile.uid}`)
          .valueChanges()
          .takeUntil(this.leaving)
          .subscribe(messages => {
            if(!messages){this.unread = 0; return}
            let keys = Object.keys(messages)
            var total = 0
            keys.forEach(key => total += messages[key])
            this.unread = total
          }) 
        }
      } else {
        if(this.sub){
          this.leaving.next(null)
          this.sub.unsubscribe()
          this.sub = null
          this.unread = 0
          this.parent = null
        }
      }
    })

    var childSubject = this.um.getActiveChild()
    childSubject.subscribe(child => {
      this.activeChild = child
    })
  }

  showSignUp() {
    // this.um.openSignUp()
  }

  showLogin() {
    // this.um.openLogin() 
  }

  gotoGames(){
    this.um.gotoGames()
  }
  logout() {
    // call logout here
    this.um.openUserSelect();

  }
  gotoMyMessages(){
    // this.router.navigate(['/messages'])
  }


  gotoMyLessons(){
    this.router.navigate(['/my-lessons']);
  }

  gotoLessons() {
    this.router.navigate(['/lessons-information']);
  }

  gotoHome() {
    // this.router.navigate(['']);
  }
  gotoContactUs() {
    this.router.navigate(['/contact-us']);
  }
  gotoMMWJ(){
    
  }

}
