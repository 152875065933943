import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, OnDestroy ,EventEmitter} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {Subject} from 'rxjs/Subject'
import { Parent } from '../../Models/user.interface';
import { LessonManagerService } from '../../Services/LessonManager/lesson-manager.service';
import { LessonCard } from '../../Models/lessons.interface';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnChanges, OnDestroy {
@Input() id: string;
@Output() close = new EventEmitter<null>();
  uid;
  leaving = new Subject<any>()
  profile: Parent
  confirmations = Array<Confirmation>()
  cards: Array<LessonCard>
  confs = [
    17,18,19,15,19,17,18,19,17,18,20,17,19,16,17,15,20,14,17,17,17,19,21,17,19
  ]
  constructor(private db: AngularFireDatabase, private lm: LessonManagerService) { 
    this.cards = this.lm.getLessonCards()
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges){
    var u = changes['id'].currentValue
    if(u){
      this.getUser(u)
    }
  }

  getUser(uid: string){
    this.db.object<Parent>(`profile/${uid}`)
    .valueChanges()
    .takeUntil(this.leaving)
    .subscribe(profile =>  {
      // console.log(profile)
      this.profile = profile
      if(profile.status){
        for(let i in this.confs){
          var c = {} as Confirmation
          var index = +i+1
          var val = profile.status[`${index}-${this.confs[i]}`]
          if(val){ c.response = val['0'].choice; c.index = +i+1; this.confirmations.push(c)}
        }
      }
    })




  }
  ngOnDestroy(){
    this.leaving.next(null)
    this.confirmations = []
    this.profile = null
  }
  onClose(e){
    e.stopPropagation()
    this.close.emit(null)
    this.ngOnDestroy()
  }
  doNothing(e){
    e.stopPropagation()
  }

}
interface Confirmation{index: any, response: any}
