

  <div class="background" [@title]=currentState>
      <div class="inset" [@title]=currentState>
        <h1>My Study Guides</h1>
        <p>Here are the guides you've unlocked.  You can review an unlocked guide at any time by clicking on it.</p>
        <div class = "horizontalLine"></div>
      </div>
      <br><br>
      <div class="inset">
        <!-- <app-lesson-blocks (onLessonChoice)="lessonHeaderBar.setLesson($event); sendTitleOut();"></app-lesson-blocks> -->
        <app-lesson-blocks (onLessonChoice)="sendTitleOut();"></app-lesson-blocks>
      </div>
    </div>