import { Component, OnInit, OnDestroy , Input, Output, EventEmitter} from '@angular/core';
import { LessonCard } from './../../Models/lessons.interface';
import { LessonManagerService } from './../../Services/LessonManager/lesson-manager.service';
import { UserManager } from '../../Services/UserManager/user-manager';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { trigger, state, style , animate, transition, query, stagger, keyframes  } from '@angular/animations';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';

@Component({
  selector: 'app-lesson-blocks',
  templateUrl: './lesson-blocks.component.html',
  styleUrls: ['./lesson-blocks.component.css'],
  animations: [
    trigger('guideAnimation', [
        // state('void', style({
        //     // transform: 'scale(0)',
        //     opacity: 0
        // })),
        state('locked', style({
            // transform: 'scale(1)',
            opacity: 1
        })),
        state('unlocked', style({
            // transform: 'scale(1)',
            opacity: 1
        })),
        transition('void => *',[
            animate('400ms',
            keyframes([
                style({ opacity: 0, offset:0.1}),
                style({ opacity: 1, transform: 'scale(1)', offset:0.2}),
                style({ transform: 'scale(1.2)', offset:0.4}),
                style({ transform: 'scale(0.9)', offset:0.7}),
                style({ transform: 'scale(1)'  , offset:0.9})
            ]))
        ]),
        transition('* => void',[
          animate('400ms',
          keyframes([
              style({ opacity: 1, offset:0.1}),             
              style({ opacity: 0, transform: 'translateY(25%)'  , offset:0.9})
          ]))
      ]),





    ]),
    trigger('mouse',[
        state('rest', style({
            transform: 'scale(1)'
        })),
        state('hover', style({
            transform: 'scale(1.1)'
        })),
        state('press', style({
            transform: 'scale(0)'
        })),
        transition('rest => hover', animate('200ms ease-in-out')),
        transition('hover => rest', animate('200ms ease-out')),
        // transition('hover => press', animate('200ms ease-in')),
        transition('press => rest', animate('200ms ease-out')),
        transition('* => press',[
          animate('600ms',
          keyframes([
              style({ transform: 'scale(1)' ,  offset:0.1}),
              style({ transform: 'scale(0.8)', offset:0.2}),
              style({ transform: 'scale(1.4)', offset:0.4}),
              style({ transform: 'scale(0.7)', offset:0.7}),
              style({ transform: 'scale(0)'  , offset:0.9})
          ]))
      ]),
      ]),
      trigger('all',[
        state('void', style({
            opacity: 0
        })),
        transition('* => void',[
          animate('400ms',
          keyframes([
              style({ opacity: 1, offset:0.1}),             
              style({ opacity: 0, transform: 'translateY(25%)'  , offset:0.9})
          ]))
       ]),
      ])
    ]
})

export class LessonBlocksComponent implements OnInit, OnDestroy {
  currentState = 'show'
  lessonAnimation = 'rest'
  lessonCards: Observable<Array<LessonCard>>;
  unlocked: Array<string>;
  unlockSubscription: Subscription;
  mouseTriggers = Array<string>();
  guideTriggers = Array<string>();
  pressed = false;
  @Output() onLessonChoice = new EventEmitter<LessonCard>();
  lessonChoice: LessonCard;
  constructor(private lm: LessonManagerService,
              private um: UserManager,
              private scroll: ScrollService) { }

  ngOnInit() {
    var num = 1
    var limit = 25
    while( num <= limit){
      this.mouseTriggers.push('rest')
      this.guideTriggers.push('void')
      num += 1
    }
    this.load()
    
  }


  load(){

    this.lessonCards = this.um.getUnlockSubject()
    this.unlockSubscription  = this.lessonCards.subscribe(lessons => {
      // console.log('called')
      if(lessons){
        for (let lesson of lessons){
          if (lesson.unlocked){
            // console.log(`Lesson ${lesson.id} state = ${this.guideTriggers[lesson.id -1]}`)
            this.guideTriggers[+lesson.id -1] = 'unlocked'}
          else {this.guideTriggers[+lesson.id -1] = 'locked'}
          
        }
      }
    })
  }
  gotoLessonHeader(lesson: LessonCard){

    //animate the item off the screen, fade the rest out
    if (lesson.unlocked){
      this.guideTriggers[lesson.id - 1] = 'selected'
      //this.um.gotoLessonHeader(lesson.id)
    } else {
      //put locked animation here
    }
  }
  ngOnDestroy(){
    if ( this.unlockSubscription){this.unlockSubscription.unsubscribe()}
  }

  start(e){
    // console.log(e)
  }

  end(e){
    // console.log(e)
  }

  animateEnd(e){
    if (e.phaseName ==='done' && e.toState === 'press'){
     
      // call the animation to show the lesson header picture
     let emit = new EventEmitter<boolean>()
     setTimeout(() => this.um.gotoLessonHeader(this.lessonChoice.id),500  )
     this.scroll.scrollToElement('#logo', emit)
     this.onLessonChoice.emit(this.lessonChoice)
     this.currentState = 'void'
    }
 }


  setMouse(state, lesson) {
    if(this.pressed){
      return
    }
    if(state === 'press'){
      this.lessonChoice = lesson
      this.pressed = true
      // reject all hover states from here on out as we will transition to a new view
    }

    if(lesson){
      this.mouseTriggers[lesson.id-1] = state
      this.lessonAnimation = state
    } 

  }

 }

