import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crossword-page',
  templateUrl: './crossword-page.component.html',
  styleUrls: ['./crossword-page.component.css']
})
export class CrosswordPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
