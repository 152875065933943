<div class="outerContainer"style="position: relative;">   
    <div [ngClass]="{'show': count < 20}" class="forward" (click)="scrollRight()"> &gt; </div>      
    <div [ngClass]="{'show': count > 5}" class="backward" (click)="scrollLeft()"> &lt; </div>
    <div class="responsive" id="flatLessonContainer" >
        <div  *ngFor="let lesson of lessonCards" class="gallery">
                <div [attr.id]=lessonIDs[lesson.id-1] class="lessonContainer" (click)="chooseLesson(lesson)" >
                    <img src={{lesson.img}} class="lessonPhoto">
                    <div class="greyText lessonIndex captionText">Guide {{lesson.id}}</div>
                    <div class="darkGreyText lessonTitle regularText">{{lesson.title}}</div>
                </div>

        </div>
    </div>

</div>