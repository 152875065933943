
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 3000 4000" style="enable-background:new 0 0 3000 4000;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#76CCCC;}
	.st1{fill:#1E3751;}
</style>
<path class="st0" d="M3619.7,2949c-55.7,0-691.2,176.9-1965.5-249s-2257-32.8-2257-32.8v1368.1h4222.5V2949z"/>
<path class="st1" d="M3619.7,3067.1c-55.7,0-691.2,176.9-1965.5-249c-1274.3-425.8-2257-32.8-2257-32.8v1368.1h4222.5V3067.1z"/>
</svg>

