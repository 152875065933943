import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, state, transition, animate, style} from '@angular/animations'
import {instruction} from '../../Models/instruction.interface'
@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css'],
  animations: [
    trigger('trigger', [
        state('void', style({  opacity: 0, transform: 'scale(0)'})),
        state('show', style({  opacity: 1, transform: 'scale(1)'})),
        transition('void => *',[ animate('300ms ease-in-out')]),
        transition('* => void',[ animate('300ms ease-in-out')]),
      ])
    ]
})

export class InstructionComponent implements OnInit , OnChanges{
@Input() img: string;
@Input() text: string
trig = 'show'
innerImg: string;
innerText: string;
  constructor() { }

  ngOnInit() {
    this.innerImg = this.img
    this.innerText = this.text
  }
  ngOnChanges(changes: SimpleChanges){
    this.trig = 'void'

  }

  set(e){
    if(e.fromState === 'show' && e.toState === 'void'){
      this.innerImg = this.img
      this.innerText = this.text
    } 
    this.trig = 'show'
  }

}
