import { Component, OnInit } from '@angular/core';
import { GlobalVariablesService } from '../../Services/globalVariables/global-variables.service';
import { UserManager } from '../../Services/UserManager/user-manager';
import { Parent } from '../../Models/user.interface';
import { studentQuestion } from '../../Models/studentQuestion.interface';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import {trigger, state, transition, style, animate} from '@angular/animations'
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],   
  animations: [
    trigger('trigger', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('400ms ease-in-out')]),
        transition('* => void',[ animate('400ms ease-in-out')]),
  ])]
})

export class ContactUsComponent implements OnInit {
  name: string;
  email: string;
  bibleQuestion: boolean;
  supportQuestion: boolean ;
  other: boolean;
  message: string;
  verificationMessage: boolean = false;
  errorMessage: string;
  currentState = 'void'
  profile: Parent;
  constructor(private gv: GlobalVariablesService,
              private um: UserManager,
              private scroll: ScrollService) { }

  ngOnInit() {
    this.scroll.scrollToElement('#logo')
    this.currentState = 'show'
    this.um.getProfileSubject().subscribe(profile => this.profile = profile)
  }


  submitMessage() {
    this.gv.print(this.name);
    this.gv.print(this.email);
    this.gv.print(this.bibleQuestion);
    this.gv.print(this.message);
    if (!this.name){
      this.gv.print("No Name Provided")
      this.displayErrorMessage("No Name Provided")
      return
    }

    if(!this.email){
      this.gv.print("No Email Provided")
      this.displayErrorMessage("No Email Provided")
     return 
    }

    if (!this.message){
      this.gv.print("No Message Provided")
      this.displayErrorMessage("No Message Provided")
      return
    }
    var question = {} as studentQuestion


    question.name = this.name;
    question.email = this.email;
    question.message = this.message;
    question.bibleQuestion = this.bibleQuestion ? this.bibleQuestion : false;
    question.supportQuestion = this.supportQuestion ? this.supportQuestion : false;
    question.otherTypeQuestion = this.other ? this.other : false;
    question.unread = true;
    question.date = new Date().getTime()
    question.sortDate = 0 - new Date().getTime()
    question.anonymous = this.profile.anonymous || false

    if(this.profile){

      question.profileEmail = this.profile.email ? this.profile.email : "";
      question.profileName = this.profile.name ? this.profile.name : "";
      if (this.profile.uid){
        question.uid = this.profile.uid
        // this.um.saveMessageFromUser(question)
      }
    }
    this.sendMessage(question)
    
  }

  sendMessage(message: studentQuestion){

    this.gv.print('sending message')
    this.um.sendMessage(message).then(result => 
      {
        if(result){
          this.gv.print("Message Sent")
           this.displaySuccessMessage()
           this.message = "";
           this.name = "";
           this.email = "";
           this.bibleQuestion = undefined;
           this.supportQuestion = undefined;
           this.other = undefined;
        } else {
          this.displayErrorMessage("That didn't quite work.  Please try again later, or try calling us")
        }
    })

    
  }

  displaySuccessMessage(){
    this.verificationMessage = true
  }
  hideSuccessMessage(){
    this.verificationMessage = false
  }

  displayErrorMessage(message: string){
    this.errorMessage = message
  }
  hideErrorMessage(){
    this.errorMessage = null
  }
}

