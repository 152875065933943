<div class="titleBarBackground">
        
        <div  id="dots" class="headerBackGround"   >
                <div class="inset" >
                    <div class="titleBarText">
                     <br>
                     <h4 class=" titleBarText accentText" >Guide {{lessonCard?.id}}</h4>
                     <h1 class="accentText" >{{lessonCard?.title}}</h1>
                   </div>
               </div>
              </div>
            <img src={{lessonCard.img}}  class="titleImage">
</div>
        

<div id="background" class="background" [@trigger]="currentState" >
    <div  class="rubber-indicator">
        <ol  class="page-dots">
        <span  *ngFor="let d of questions$ | async | slice:1">
            <!-- {{d.allCorrect}} -->
          <li class="dot" [ngClass]="{current: d.questionID == currentQuestionID, correct: isCorrect(d.questionID), incorrect: !isCorrect(d.questionID)}"
           (click)="goto(d.questionID); scrollToQuestion()">{{d.allCorrect}}</li>          
        </span>
    </ol>
    </div>

    <div id="lessonTopAnchor"></div>
    <div *ngFor="let q of questions$ | async" class="questionContainer" >
        <app-question-template  
        style="min-height: 30vh"
        class="questionMargin hide" 
        [attr.id]=q.questionID 
        [lessonID]="lessonCard.id" 
        [questionID]="q.questionID" 
        (complete)="markComplete($event)" 
        (incomplete)="markIncomplete($event)"
         ></app-question-template>
         <!-- (complete)="checkIfAllCorrect()"  -->
    </div>  
    <div [@item] *ngIf="!hasNextQuestion" class="activity">
        <img src={{activityImage}}>
        <a class=button href={{activity}} download="Activity{{lessonID}}">Download</a>
    </div>

    <div *ngIf="hasPreviousQuestion" class="mobileBackButton" (click)="goBack(); scrollToQuestion()"><div class="r180"><h1 style="margin: 0">&#10150;</h1></div></div>
    <div *ngIf="hasNextQuestion" class="mobileForwardButton" (click)="goForward();  scrollToQuestion()"><h1 style="margin: 0">&#10149;</h1></div>
    <div *ngIf="allQuestionCorrect && !hasNextQuestion" class="mobileForwardButton" (click)="finishLesson()"><h1 style="margin: 0">&#10149;</h1></div>
    <div *ngIf="!allQuestionCorrect && !hasNextQuestion && currentQuestionID !== firstIncomplete" class="mobileForwardButton" (click)="gotoLastUnansweredQuestion()" >Q{{firstIncomplete}}</div>

    <div class="navigationButtonContainer">
        <div *ngIf="hasNextQuestion" class="nextButton regularText button" (click)="goForward(); scrollToQuestion()">Continue</div>
        <div *ngIf="hasPreviousQuestion" class="previousButton regularText button" (click)="goBack(); scrollToQuestion()">Go Back</div>
        <div *ngIf="!allQuestionCorrect && !hasNextQuestion && currentQuestionID === firstIncomplete" class="unansweredNotification regularText button" (click)="gotoLastUnansweredQuestion()" >Answer Above</div>
        <div *ngIf="!allQuestionCorrect && !hasNextQuestion  && currentQuestionID !== firstIncomplete" class="unansweredNotification regularText button" (click)="gotoLastUnansweredQuestion()" >Question {{firstIncomplete}}</div>
        <div *ngIf="allQuestionCorrect && !hasNextQuestion && lessonID != '25'" class="nextButton regularText button" (click)="finishLesson()" >Finished!</div>
        <div *ngIf="allQuestionCorrect && !hasNextQuestion && lessonID == '25'" class="nextButton regularText button" (click)="finishCourse()" >Finished!</div>
    </div>


</div>

