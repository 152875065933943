import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore'
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { Parent, Answer, Child } from '../../Models/user.interface';
import { Subscription } from 'rxjs/Subscription';
import { LessonCard } from '../../Models/lessons.interface';
import 'rxjs/add/operator/take'
import 'rxjs/add/operator/takeUntil'
import { LessonManagerService } from '../LessonManager/lesson-manager.service';
import { GlobalVariablesService } from '../globalVariables/global-variables.service';
import { studentQuestion } from '../../Models/studentQuestion.interface';
import { Injectable} from '@angular/core';
import { Message, ConversationMeta, Member } from '../../Models/conversation.interface';
import { Error } from '../../Models/error.interface';

@Injectable()
export class UserManager {
  
  // authSubscription: Subscription;
  private parentProfile: Parent; 
  private shouldGotoLesson: boolean = false;
  private user;
  private lessonCards: Array<LessonCard> = this.lm.getLessonCards();
  private unlockSubject = new BehaviorSubject<Array<LessonCard>>(this.lessonCards)
  private unlockObjectSubject = new BehaviorSubject<Object>({"1":true})
  private parentSubject = new BehaviorSubject<Parent>(null)
  private unreadSubject = new BehaviorSubject<number>(0)
  private unreadSubscription: Subscription;
  private loginDisplay = new BehaviorSubject<string>(null)
  private leaving = new Subject<null>()
  private activeChild: Child;  
  
  childSubject = new BehaviorSubject<Child>(null)

  constructor(private auth: AngularFireAuth,
              private db: AngularFireDatabase,
              private afs: AngularFirestore,
              private lm: LessonManagerService,
              private router: Router,
              private gv: GlobalVariablesService
              ) {
          this.auth.onAuthStateChanged(user => {
            if (user) {
              this.user = user;
              if(!user.isAnonymous){this.chooseChild()}
              this.afs.doc<Parent>(`Parents/${this.user.uid}`)
              .valueChanges()
              .takeUntil(this.leaving)
              .subscribe(parentProfile => {
                this.emitParent(parentProfile)
                if(!parentProfile && user.isAnonymous){
                  // this.createAnonymousParentProfile(user.uid)
                }
                })
             } else {
               this.leaving.next(null)
               this.emitChild(null)
               this.emitParent(null)
               this.hideLoginContainer()
             }
          })
  }



  chooseChild(){
    this.openUserSelect()
  }


  displayLesson(): number{
    return +this.parentProfile.currentLesson
  }

  async isLoggedIn(): Promise<Boolean> {
    let isLoggedIn = await this.auth.currentUser 
    return !!isLoggedIn

  }


  saveAnswer(answer: Answer, lessonID: string, questionID: string):Promise<boolean> {
      // create or override the current value for the question response.
      var profile;
      var collection;
    if(this.activeChild && this.parentProfile){
      collection = 'Children'
      profile = this.activeChild
    } else if (!this.activeChild && this.parentProfile){
      collection = 'Parents'
      profile = this.parentProfile
    } else {
      return Promise.resolve(false)
    }
    if(!profile || !collection) return

    return new Promise((resolve, reject) => {
      this.afs.collection(collection)
      .doc(profile.uid)
      .collection('status')
      .doc(`${lessonID}-${questionID}-${answer.blankID}`)
      .set(answer)
      .then(() => {      
      this.updateLastUpdate(collection, profile)
        .then( () =>  resolve(true))
        
      })
      .catch(
        e => {console.error(e); this.error(e);reject(e)}
        )
    })
  }

  removeAnswer(answer: Answer, lessonID: string, questionID: string): Promise<any> {
      // create or override the current value for the question response.

        var profile;
        var collection;
      if(this.activeChild && this.parentProfile){
        collection = 'Children'
        profile = this.activeChild
      } else if (!this.activeChild && this.parentProfile){
        collection = 'Parents'
        profile = this.parentProfile
      } else {
        return Promise.resolve(false)
      }

      if(!profile || !collection) return
        return new Promise((resolve, reject) => {
            this.afs.collection(collection)
            .doc(profile.uid)
            .collection('status')
            .doc(`${lessonID}-${questionID}-${answer.blankID}`)
            .delete()
            .then(() => {
              this.updateLastUpdate(collection, profile)
              resolve()
            })
            .catch(e => reject(e))
        })
  }

  private updateLastUpdate(collection, profile){
  return new Promise((resolve) => {
    this.afs.collection(collection)
    .doc<any>(profile.uid)
    .valueChanges()
    .take(1)
    .subscribe(profile => {
      if(profile){
        profile.lastUpdated = new Date().getTime()
        if(collection === 'Children'){  this.updateChildProfile(profile)}
        if(collection === 'Parents'){ this.updateParentProfile(profile)}
      }
      resolve()
    })
  })

  }

  addChild(newChild: string):Promise<any>{
    return new Promise((resolve,reject) => {
      this.checkIfChildExists(newChild)
      .then(exists => {
        if(exists){
          reject('Child Already Exists')
        } else {
          this.createChildProfile(newChild)
          .then(() => {resolve()})
          .catch(e => reject(e))
        }
      })
    })

  }

  setActiveChild(child: Child):Promise<boolean>{
    return new Promise((resolve, reject) =>{
      if(!child){
        this.emitChild(null);
        this.emitParent(this.parentProfile); 
        this.gotoMyLessons(); resolve()
      }
      else if(!this.parentProfile){reject('No Parent')}
      else if(child.parent !== this.parentProfile.uid){reject('Child doens\'t belong to Parent')}
      else if(this.activeChild && child.uid === this.activeChild.uid){resolve(true)}
      else {
        this.emitChild(null)
        this.emitChild(child) // sets the active child and distributes the child notification
        this.gotoMyLessons()
        resolve(true)  
      }
    })

    
  }
  private checkIfChildExists(child: string):Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.afs.collection(
        'Children',
         ref => ref.where('parent', '==', this.parentProfile.uid))
         .valueChanges()
         .take(1)
         .subscribe(children => {
            const result = children.includes(child)
            resolve(result)
         })
    })
  }

  private createChildProfile(name: string){
    return new Promise((resolve, reject) => {
      var child = {} as Child;
      child.name = name
      child.currentLesson = 1
      child.currentQuestion = 1
      child.parent = this.parentProfile.uid
      child.toured = false
      child.color = this.getRandomColor()
      child.lastUpdated = new Date().getTime()
      this.afs.collection('Children')
      .add(child)
      .then(addedChild => {
        child.uid = addedChild.id
        this.updateChildProfile(child)
        .then(() => resolve())
        .catch()
      })
      .catch(e => reject(e))
    })

  }
  private getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getAnswersSubscription(lessonID: number, questionID: number, blankID: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.auth.currentUser) {
        reject('No User Logged In') ;
      }
      var collection;
      var profile;
      if(this.parentProfile && this.activeChild){
        collection = 'Children'
        profile = this.activeChild
      } else if (!this.activeChild && this.parentProfile){
        collection = 'Parents'
        profile = this.parentProfile
      } else {
        reject('No logged in')
      }
      let sub = 
        this.afs.collection(collection)
        .doc(profile.uid)
        .collection('status')
        .doc(`${lessonID}-${questionID}-${+blankID}`)
        .valueChanges()
        .takeUntil(this.leaving)
      resolve(sub)
   
   
      
    })
}

  bypassToLesson = async (id: number) => {
      if(!id){
        // this.gotoHome()
        // goto some error page
      } else {
        try{
          await this.anonymousLogin(id)
          await this.unlockLesson(id)
          await this.gotoLessonHeader(id)

          // const loggedIn = await this.isLoggedIn()
          // if(loggedIn && !this.user.isAnonymous ){
          
          //   this.gotoLessonHeader(id)
          // } else if(loggedIn && this.user.isAnonymous){
          //   await this.unlockLesson(id)
          //   await this.updateBypassValue(true)
          //   this.gotoLessonHeader(id)
          // } else {
          //   this.bypassId = id; // the login flow will use this id to take the user to the lesson
            
          // } 
      } catch(e){
        console.error(e)
        alert(e)  
      }
    }
  }

  // login logout functions
  async loginWithEmailAndPassword(email: string, password: string): Promise<string> {
    try{
      if( await this.isLoggedIn()) {
        if(this.user.isAnonymous === true) {
          this.logout(false)
          await this._login(email, password)
          return 'Welcome Back'

        } else {
          return; 
        }
      }
      await this._login(email, password)
      return 'Welcome Back'
    } catch(e) {
      return Promise.reject(e)
    }

  }

  private _login(email: string, password: string) :Promise<any>{
    return new Promise((resolve, reject) =>{
      this.auth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.openUserSelect()
        resolve(user)
        
      }).catch(e => {this.error(e), 'Login Failure'; reject( this.decodeLoginError(e)) } );
    })

  }

  resetPassword(email: string):Promise<string>{
    return new Promise((resolve, reject) => {
      this.auth.sendPasswordResetEmail(email)
      .then(success => resolve('Email Sent'))
      .catch(e => {
        
        if(e.code === 'auth/user-not-found'){
          reject('You don\'t seem to have an account here.  Try signing up instead.')
        } else {
          this.error(e,'Failed to send password reset email') 
          e.message && reject(e.message)
          !e.message && reject("That didn't quite work.  Send us a message about your issue");
        }

        
      })
    })
  }
  error(error: any, description: string = "", inLesson: boolean = false){
    var err = {} as Error
    err.date = new Date().toDateString()
    err.uid = this.parentProfile ? this.parentProfile.uid : ""
    err.description = description
    err.inLesson = inLesson
    if(inLesson){
      err.lessonID = this.parentProfile.currentLesson
      err.questionID = this.parentProfile.currentQuestion
    }
    err.error = error
    this.db.list('Errors').push(err)
  }

  private decodeLoginError(e): string{
    switch(e.code){
      case 'auth/invalid-email' : return "You're email wasn't valid."
      case 'auth/user-disabled' : return "You're account has been disabled, probably due to suspicious activity.  Please contact us to enable it."
      case 'auth/user-not-found' : return "We don't seem to have any information for you.  Have you signed up?"
      case 'auth/wrong-password' : return "You're email or password is incorrect"
      default: return e.message
    }
  }

  async signUpWithEmailAndPassword(
    email: string, 
    password: string, 
    firstname: string,
    lastname: string,
    emailConf: any = null,
    passwordConf: any = null,
    ofAge: boolean
  ): Promise<string>{
      if( this.user) {
        if(this.user.isAnonymous) {
          return Promise.reject('Anonymous Users Need to upgrade, not sign up')
        }
      }
      try{
        let { user } = await this.auth.createUserWithEmailAndPassword(email, password)
        if(!user){
          return Promise.reject('Error occured on profile creation')
        }
        emailConf = emailConf ? emailConf : false
        passwordConf = passwordConf ? passwordConf : false

        await this.afs.collection('Parents')
          .doc(user.uid)
          .set({
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
            'uid': user.uid,
            'emailConf': emailConf,
            'passwordConf': passwordConf,
            'ofAge': ofAge
          });
          this.hideSignUpContainer();  
          return `Welcome ${firstname}`
      } catch(e) {
        this.error(e,'Error Occurred on profile creation', false); 
        return Promise.reject(this._decodeCreateUserError(e)) 
      }
  }

  _decodeCreateUserError(e){
    switch(e.code){
      case 'auth/email-already-in-use' :return 'The email you chose is already in use.  Try another one.'
      case 'auth/invalid-email' : return "You're email isn't valid."
      case 'auth/operation-not-allowed' : return "Something went wrong.  We logged the error.  Please Contact Support."
      case 'auth/weak-password' : return "You're password wasn't strong enough. You need at least 7 characters"
      default: return e.message
    }
  }
  async upgrade(email: string, password: string, firstname: string, lastname: string, emailConf: any = null, passwordConf: any = null, ofAge: boolean){
      try {
        await this.upgradeAccount(email, password)  
        await this.upgradeProfile(firstname, lastname, ofAge) 
        this.openUserSelect()
        return `Welcome ${firstname}`
      }
      catch(err){
        return err.message
      }
  }

  async upgradeAccount(email: string,password: string){
    try{
      let user = await this.auth.currentUser
      let cred = auth.EmailAuthProvider.credential(email, password)
      let results = await user.linkAndRetrieveDataWithCredential(cred)
      return results
    } catch(e) {
      this.error(e, 'Anonymous upgrade attempt', false)
      return Promise.reject(this._decodeUpgradeError(e))
    }       
  }

  _decodeUpgradeError(e){
    switch(e.code){
     case 'auth/provider-already-linked' : return "You've already upgraded your account."
     case 'auth/invalid-credential' : return "You're login information is invalid.  "
     case 'auth/credential-already-in-use' : return "Sorry, that email is already taken."
     case 'auth/email-already-in-use' : return "Sorry, that email is already in use."
     case 'auth/operation-not-allowed' : return "An error occurred."
     case 'auth/invalid-email' : return "Invalid email entered."
     case 'auth/wrong-password' : return "Password incorrect."
     case 'auth/invalid-verification-code' : return "Invalid verification code"
     case 'auth/invalid-verification-id' : return "Invalid Verification ID"
     default: return e.message
    }

  }

  upgradeProfile(firstname: string, lastname: string, ofAge: boolean = false){
    return new Promise((resolve, reject) =>{
      if(!firstname) return
      if(!this.parentProfile) return 
      this.parentProfile.firstname = firstname
      this.parentProfile.lastname = lastname || ""
      this.parentProfile.toured = true
      this.parentProfile.ofAge = ofAge
      this.parentProfile.lastUpdated = new Date().getTime()
      this.parentProfile.anonymous = false
      this.updateParentProfile(this.parentProfile)
      .then(() => resolve())
      .catch(e => reject(e))
    })


  }
   

  // sendWelcomeMessage(){
  //   if(!this.parentProfile){return}
  //   var mess = {} as Message
  //   mess.message = `Welcome ${this.parentProfile.firstname}! Glad you're here.`
  //   this.startMessageBySystem(mess)
  // }

  // async loginAndGotoLesson() {
  //   if (await this.isLoggedIn()){
  //     this.gotoLesson();
  //     return;
  //   }
  //   this.openLogin();
  //   this.shouldGotoLesson = true;
  // }



  transferStatus(newUID, oldUID):Promise<any>{
    return new Promise((resolve, reject) => {
      this.afs.collection(`Parents/${oldUID}/status`)
      .valueChanges()
      .take(1)
      .subscribe(results => {
        if(results){
          results.forEach(result => {
            this.afs.collection(`Parents/${newUID}/status`)
            .add(result)
            .catch(e => reject(e))
          })
          resolve()
        }
      })
    })

  }

  deleteAnonymousUser(oldUID){
    return new Promise((resolve, reject) => {
      this.deleteOldStatus(oldUID)
      .then(() => {
          this.afs.collection('Parents')
          .doc(oldUID)
          .delete()
          .then(() => resolve())
          .catch(e => reject(e))
        })
        .catch(e => reject(e))
      })
  }

  deleteOldStatus(oldUID){
    return new Promise((resolve, reject) => {
      this.afs.collection('Parents')
      .doc(oldUID)
      .collection('status')
      .snapshotChanges()
      .take(1)
      .subscribe(action => {
        action.forEach(docAction => {
          docAction.payload.doc.ref.delete()
        })
        resolve(true)
      })
    })
  }

  async getUserFromAuth() {
    return this.auth.currentUser
  }
  async logout(navigate: boolean = true) {
    
    let user = await this.getUserFromAuth()
    // this.gv.pr nt(`profile.anonymous = ${this.profile.anonymous}`)
    if (user.isAnonymous) {
      this.emitParent(null)
      try{
        await user.delete()
        this.gv.print("User Deleted");
        this.auth.signOut()
      } catch(e) {
        this.gv.print(`failed to delete user`);
        this.error(e);
        this.auth.signOut()
          .then( stuff => this.gv.print('logged Out'))
          .catch((e) => this.error(e)) 
        this.parentProfile = null;
        this.user = null;
      }

    } else {
      this.emitParent(null)
      this.emitChild(null)
      this.auth.signOut()
      .then( () => {this.gv.print('logged Out');})
      .catch((e) => this.error(e, 'Failed to logout')) 
      this.parentProfile = null;
      this.user = null;
    }
    if (navigate){
      this.router.navigate(['']);
  }
  
  }


  // status functions
  getCurrentLesson(): Promise<number>{
   return new Promise((resolve, reject) => {
      if (this.parentProfile){
        resolve(this.parentProfile.currentLesson)
      } else {
        reject()
        
      }
    });
  }

  getCurrentQuestion(lessonID: number = 1): Promise<number>{
    return new Promise((resolve, reject) => {
       if (this.parentProfile && !this.activeChild){
         if (this.parentProfile.currentQuestion && this.parentProfile.currentLesson === lessonID){
          resolve(this.parentProfile.currentQuestion);
         } else {
           resolve(1);
         }
       } else if(this.activeChild && this.parentProfile){
         if(this.activeChild.currentQuestion && this.activeChild.currentLesson === lessonID){
           resolve(this.activeChild.currentQuestion)
         } else {
           resolve(1)
         }
       } 
       else {
         reject('No User Found');
       }
     });
   }

  async anonymousLogin(bypassId: any) : Promise<boolean> {
    try{
      let { user } = await this.auth.signInAnonymously()
        this.gv.print(JSON.stringify(user))
        if(!!user) {
          await this.createAnonymousParentProfile(user.uid, bypassId)
          return true
        } else { 
          return Promise.reject('Failed to login anonymously')
        }
      } catch(e) {
        return Promise.reject(e)
      }
  }

  createAnonymousParentProfile(uid: string, bypassId: any){
    return new Promise((resolve, reject) => {
      let parent = {} as Parent;
      parent.anonymous = true
      parent.uid = uid
      parent.currentLesson = bypassId || 1;
      parent.currentQuestion = 1;
      
      this.afs.collection('Parents')
        .doc(parent.uid)
        .valueChanges()
        .take(1)
        .subscribe( async profile => {
          if(profile){
            await this.afs.collection('Parents')
            .doc(parent.uid)
            .update(parent)
          } else {
            await this.afs.collection('Parents')
            .doc(parent.uid)
            .set(parent)
          }
          this.emitParent(parent);
          resolve(true)
      })
    })


      
   }


  saveCurrentQuestion(lessonID: number,  questionID: number) {
    if(this.activeChild && this.parentProfile){
      this.activeChild.currentLesson = lessonID
      this.activeChild.currentQuestion = questionID
      this.updateChildProfile(this.activeChild)
    }  else if(this.parentProfile && !this.activeChild){
      this.parentProfile.currentLesson = lessonID
      this.parentProfile.currentQuestion = questionID
      this.updateParentProfile(this.parentProfile)
    } 
  }


  getPreviousUnlocked(lesson: number): Promise<number>{

    return new Promise((resolve, reject) => {
      if (lesson === 1){ resolve(1)}
      // console.log(this.profile.unlocked)
      if (!this.activeChild && this.parentProfile && this.parentProfile.unlocked){
        var sorted = Object.keys(this.parentProfile.unlocked).filter(item => +item < lesson).sort((n1,n2) => +n1 - (+n2))
        var result = sorted[sorted.length -1]
        if( result){resolve(+result)  }
        
        else {resolve(1)}
      } else if(this.activeChild && this.parentProfile && this.activeChild.unlocked) {
        var sorted = Object.keys(this.activeChild.unlocked).filter(item => +item < lesson).sort((n1,n2) => +n1 - (+n2))
        var result = sorted[sorted.length -1]
        if( result){resolve(+result)  }
      } else { 
         resolve(1)
        }

    })
  }

  emitChild(child: Child){
    this.activeChild = child
    this.childSubject.next(child)
    if(this.activeChild && this.activeChild.unlocked) this.unlockObjectSubject.next(this.activeChild.unlocked)
    this.updateUnlockedLessons()

  }

  emitParent(profile: Parent){
    if(!profile){
      this.parentSubject.next(null)
      this.leaving.next(null)
      this.parentProfile = null
      return 
    }
    this.parentProfile = profile
      // this.unreadSubject.next(this.profile.totalMessages || 0)   
    
    this.parentSubject.next(this.parentProfile)
    if(this.parentProfile.unlocked){this.unlockObjectSubject.next(this.parentProfile.unlocked)}
    this.updateUnlockedLessons()
  }
  updateUnlockedLessons(){
    this.getAllUnlockedLessons()
    .then( unlockedLessons => {
          this.lessonCards = null
          this.lessonCards = this.lm.getLessonCards();
           unlockedLessons.forEach(result => {
             var lesson = this.lessonCards[+result-1] // lessons are 0 indexed
             if(lesson){
              lesson.unlocked = true
             }
           })
        })
        .then(() => {
          this.unlockSubject.next(this.lessonCards) 
        })
  }

  async updateParentProfile(parent: Parent){
      if (await this.isLoggedIn() && parent){
      await this.afs.collection('Parents')
          .doc(parent.uid)
          .update(parent)
        this.emitParent(parent);
      return true;
      } else {
       return Promise.reject(false);
      }
  }

  async updateChildProfile(child: Child){
    if (await this.isLoggedIn() && child){
      await this.afs.collection('Children')
              .doc(child.uid)
              .update(child)
      this.emitChild(child);
      return true;
    } else {
     return Promise.reject(false);
    }
  }

  getUnlockSubject(){
    return this.unlockSubject.asObservable()
  }
  getUnlockObjectSubject(){
    return this.unlockObjectSubject.asObservable()
  }
  getProfileSubject():Observable<Parent>{
    return this.parentSubject.asObservable()
  }

  getActiveChild():Observable<Child>{
    return this.childSubject.asObservable()
  }
  getUserProfileSnapshot(){
    return JSON.parse(JSON.stringify(this.parentProfile))
  }

  async getAllUnlockedLessons(): Promise<Array<string>>{

      if ( await this.isLoggedIn() && this.parentProfile){
        if(this.activeChild && this.parentProfile && this.activeChild.unlocked){
          return Object.keys(this.activeChild.unlocked)
        } else if (!this.activeChild && this.parentProfile && this.parentProfile.unlocked){
          return Object.keys(this.parentProfile.unlocked)
        } else {
          return ["1"]
        }
      } else {
        return Promise.reject('User not logged in.')
      }
  }

  
  checkLessonUnlocked(lesson: number): Promise<boolean>{
    return new Promise((resolve, reject) => {
      if (lesson === 1){ resolve(true)}

      if (this.parentProfile.unlocked){
        if(this.parentProfile.anonymous && lesson >=3){
          resolve(false)
        }
        if(this.parentProfile.unlocked[lesson]){
          resolve(true)
        }
      }
      resolve(false)
    })
  }

  unlockLesson(lesson:number):Promise<any>{
    return new Promise((resolve, reject) =>{
      if(this.activeChild && this.parentProfile){
        this.afs.collection('Children')
        .doc<Child>(this.activeChild.uid)
        .valueChanges()
        .take(1)
        .subscribe(profile => {
          if(!profile){ reject('No Profile Found') }
          if(!profile.unlocked){profile.unlocked = {}}
          profile.unlocked[lesson] = true
          this.updateChildProfile(profile)
          .then(() => resolve())
          .catch(e => reject(e))
        })
      } else if(!this.activeChild && this.parentProfile){
        this.afs.collection('Parents')
        .doc<Parent>(this.user.uid)
        .valueChanges()
        .take(1)
        .subscribe(profile => {
          if(!profile){ reject('No Profile Found') }
          if(!profile.unlocked){profile.unlocked = {}}
          profile.unlocked[lesson] = true
          this.updateParentProfile(profile)
          .then(() => resolve())
          .catch(e => reject(e))
        })
      } else {
        reject('No Child or Parent Profile Found')
      }

      
    })
  }

  checkAccessToLesson(lessonID: number){
    this.checkLessonUnlocked(lessonID).then( result => {
      if(!result)
      // console.log('called')
        this.router.navigate(['my-lessons'])
    })
  }

  gotoMyLessons(){
    this.router.navigate(['my-lessons'])
  }

  gotoContactUs(){
    this.router.navigate(['contact-us'])
  }

  gotoHome(){
    this.router.navigate(['home'])
  }


  finishCourse(){
    this.router.navigate(['finished'])
  }

  finishLesson = async (lesson) => {
    this.router.navigate(['lesson-completed', {lesson}])  
  }


  gotoLesson(lesson: number = 1, question: number = 1){
    lesson = lesson ? lesson : 1;
    question = question ? question : 1;
    //divert the user to the tour page the first time.
    if(this.activeChild && this.parentProfile && !this.activeChild.toured){
      this.activeChild.toured = true
      this.updateChildProfile(this.activeChild)
      .then(() => this.router.navigate(['tour', {lesson}]))
      .catch(e => this.error(e))
    } 
    if(!this.parentProfile.toured){
      this.parentProfile.toured = true
      this.updateParentProfile(this.parentProfile)
      .then(() =>  this.router.navigate(['tour', {lesson}]))
      .catch(e => this.error(e))
    }


    // if (!this.user){
    //   this.openLogin();
    // } else  if ( this.user.isAnonymous && lesson > 2){
    //     this.openSignUp();
    // } else {
    this.router.navigate(['lesson-template',{ id: lesson, question: question}]);
       
    // }

   }

  async gotoLessonHeader(lesson: number = 1):Promise<any>{
      if( !lesson ) {
        return Promise.reject('Invalid Lesosn Id Provided')
      }
      if(await this.isLoggedIn()) {
        const lessonCard = this.lm.getCard(lesson);
        if (lessonCard){
          this.router.navigate(
            ['lesson-header',
             {
              id: lessonCard.id,
              title: lessonCard.title,
              img: lessonCard.img
            }]
          )}
          return true
        } else {
          // we are not logged in.  anonymously login then segue
          let result = await this.anonymousLogin(lesson)
          if (result){
            const lessonCard = this.lm.getCard(lesson);
            if (lessonCard){  this.router.navigate(['lesson-header', {id: lessonCard.id, title: lessonCard.title, img: lessonCard.img}])}
            return true
          } else {
            this.error({}, "anonymous Login failed while trying to Segue to another page ")
            return Promise.reject("anonymous Login failed while trying to Segue to another page.")
          }
        }
   }



   sendMessage(message: studentQuestion) :Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.gv.print('sending message')
      this.db.list('studentQuestions').push(message)
      resolve(true)
    })
  }
  
  async prepareThenSendMessage(message: studentQuestion): Promise<boolean>{
    if(await this.isLoggedIn()){
      try {
        message.date = new Date().getTime()
        message.sortDate = 0 - new Date().getTime()
        message.email = this.parentProfile.email || ""
        message.profileName = this.parentProfile.firstname || ""
        message.name = this.parentProfile.name || ""
        message.unread = true
        message.uid = this.parentProfile.uid || ""
        message.anonymous = this.parentProfile.anonymous || false
        let result = await this.sendMessage(message)
        return result ? true : false;
      } catch(e){
        this.error(e,'prepare to send message failure');
        return Promise.reject(e);
      }
    } else {
      return Promise.reject('User Not logged in.')
    }
  }


  // notifyMessageMembers(members, metaID){
  //   if(!this.parentProfile){return}
  //   for(let member of members){
  //     if(member.key && member.key !== this.parentProfile.uid){
  //       this.incrementUnreadMessages(member.key, metaID)
  //     }
  //   }
  // }

  //   addUnclaimedMessage(metaID){
  //     this.db.database.ref().child('UnclaimedMessages')
  //     .child(metaID)
  //     .set(metaID)
  //   }
  //   setUnclaimedMessageAsClaimed(metaID){
  //     this.db.object(`UnclaimedMessages/${metaID}`).remove()
  //   }

  //   incrementUnreadMessages(uid: string = null, metaID: string){
  //     // do a transaction to the path node 
  //     var path: string;
  //     if(!uid){path = path}
  //     else {
  //       path = `UnreadMessages/${uid}`
  //     }
  //     this.db.object(path)
  //     .valueChanges()
  //     .take(1)
  //     .subscribe(node => {
  //       if(node){
  //         this.db.object(path).query.ref.transaction(node => {
  //           if(node){
  //               if(node[metaID]){
  //                 node[metaID] += 1
  //               } else {
  //                 node[metaID] = 1
  //               }
  //             } 
  //           return node
  //         })
  //       } else {
  //         this.db.database.ref()
  //         .child(`${path}/${metaID}`)
  //         .set(1)
  //       }

  //     })
  //   }
        
      
  //   decrememntUnreadMessages(metaID){
  //     // do a transaction to the path node 
  //     this.db.object(`UnreadMessages/${this.parentProfile.uid}`)
  //     .query.ref.transaction(node => {
  //       if(node){
  //         if(node[metaID]){
  //           delete node[metaID]
  //         } 
  //       }
  //       return node
  //     })
  //   }

    
    // assignMyselfAsInstructor(uid: string){
    //   if(!this.parentProfile){return}
    //   this.afs.collection('Parents')
    //   .doc<ParentProfile>(uid)
    //   .valueChanges()
    //   .take(1)
    //   .subscribe(profile => {
    //     if(!profile){return}
    //     profile.instructor = this.parentProfile.uid
    //     this.afs.collection('Parents')
    //     .doc(uid)
    //     .update(profile)
    //   })
    // }

  // startConversation(message: Message): Promise<boolean>{
  //     return new Promise((resolve, reject) => {
  //         if(this.isLoggedIn()){
  //           if(!this.parentProfile){reject("For some reason we couldn't find your profile.  Please try again later.  ")}
  //           message.date = new Date().getTime()
  //           message.sortDate =  0 - new Date().getTime()
  //           message.profileEmail = this.parentProfile.email || ""
  //           message.profileName = this.parentProfile.firstname || ""
  //           message.name = this.parentProfile.name || ""
  //           message.read = {}
  //           message.read[`${this.parentProfile.uid}`] = true
  //           message.creator = this.parentProfile.uid || ""
  //           message.anonymous = this.parentProfile.anonymous || false

  //           var metaData = {} as ConversationMeta;
  //           metaData.createdDate = message.date
  //           metaData.latestUpdate = message.date
  //           metaData.lastMessage = message
  //           metaData.creator = message.creator

  //           // check if user has an assigned instructor
  //           metaData.instructor = this.parentProfile.instructor || null

  //           // create members array
  //           var members = Array<Member>()
            
  //           // add myself as a member
  //           var member = {} as Member
  //           member.key = this.parentProfile.uid
  //           member.firstname = this.parentProfile.firstname || 'Bible Study Inquirer' 
  //           member.lastname = this.parentProfile.lastname || ""
  //           members.push(member) 
            
  //           //add instructor as a member
  //           if(metaData.instructor){
  //             var instructorMember = {} as Member
  //             // look up instructor first and last name
  //             this.afs.collection('Parents').doc<Parent>(`${metaData.instructor}`)
  //             .valueChanges()
  //             .take(1)
  //             .subscribe(profile => {
  //               if(profile){
  //                 instructorMember.key = metaData.instructor
  //                 instructorMember.firstname = profile.firstname || 'Instructor' 
  //                 instructorMember.lastname = profile.lastname || ""
  //                 members.push(instructorMember)
  //                 this.submitConversation(metaData, message, members)
  //                 .then(() => resolve(true))
  //                 .catch(e => reject(e))
  //               } else {
  //                 // no valid instructor found.  submit new conversation without instructor
  //                 this.submitConversation(metaData, message, members)
  //                 .then(() => resolve(true))
  //                 .catch(e => reject(e))
  //               }
  //             })    
  //           } else {
  //             // submit the new conversation without an instructor
  //             this.submitConversation(metaData, message, members)
  //             .then(() => resolve(true))
  //             .catch(e => { this.error(e, 'Failed to start conversation');reject(e)})
  //           }


  //         } else {
  //           reject("You aren't logged in.  You need to login to send a message.  ")
  //         }
  
  //     })
  //   }
  // submitConversation(metaData: ConversationMeta, message: Message, members: Array<Member>):Promise<boolean>{
  //   return new Promise((resolve, reject) => {
  //     this.afs.collection('Conversations').add(metaData)
  //     .then(docRef => {
  //       if(docRef){
  //         // add the first message
  //         this.afs.collection('Conversations').doc(`${docRef.id}`).update({key: docRef.id})
  //         this.afs.collection('Conversations').doc(`${docRef.id}`).collection('messages').add(message)
  //         for(let member of members){
  //           this.afs.collection('Conversations').doc(`${docRef.id}`).collection('members').doc(member.key).set(member)
  //         }
  //         if(metaData.instructor){
  //           this.incrementUnreadMessages(metaData.instructor, docRef.id)
  //         } else {
  //           this.addUnclaimedMessage(docRef.id)
  //         }
  //       }
  //     })
  //     .then(() => resolve(true))    })
  //   }



  // startMessageBySystem(message: Message): Promise<boolean>{
  //   return new Promise((resolve, reject) => {
  //       if(this.isLoggedIn()){
  //         if(!this.parentProfile){reject("For some reason we couldn't find your profile.  Please try again later.  ")}
  //         message.date = new Date().getTime()
  //         message.sortDate =  0 - new Date().getTime()
  //         message.profileEmail = "studies@iiw.org"
  //         message.profileName = "System"
  //         message.name ="System"
  //         message.system = true
  //         message.read = {}
  //         message.creator = this.parentProfile.uid || ""
  //         message.anonymous = this.parentProfile.anonymous || false

  //         var metaData = {} as ConversationMeta;
  //         metaData.createdDate = message.date
  //         metaData.latestUpdate = message.date
  //         metaData.lastMessage = message
  //         metaData.creator = message.creator

  //         // check if user has an assigned instructor
  //         metaData.instructor = this.parentProfile.instructor || null

  //         // create members array
  //         var members = Array<Member>()
          
  //         // add myself as a member
  //         var member = {} as Member
  //         member.key = this.parentProfile.uid
  //         member.firstname = this.parentProfile.firstname || 'Bible Study Inquirer' 
  //         member.lastname = this.parentProfile.lastname || ""
  //         members.push(member) 
          
  //         //add instructor as a member
  //         if(metaData.instructor){
  //           var instructorMember = {} as Member
  //           // look up instructor first and last name
  //           this.afs.collection('Parents').doc<Parent>(`${metaData.instructor}`)
  //           .valueChanges()
  //           .take(1)
  //           .subscribe(profile => {
  //             if(profile){
  //               instructorMember.key = metaData.instructor
  //               instructorMember.firstname = profile.firstname || 'Instructor' 
  //               instructorMember.lastname = profile.lastname || ""
  //               members.push(instructorMember)
  //               this.submitSystemConversation(metaData, message, members)
  //               .then(() => resolve(true))
  //               .catch(e => reject(e))
  //             } else {
  //               // no valid instructor found.  submit new conversation without instructor
  //               this.submitSystemConversation(metaData, message, members)
  //               .then(() => resolve(true))
  //               .catch(e => reject(e))
  //             }
  //           })  
  //         } else {
  //           // submit the new conversation without an instructor
  //           this.submitSystemConversation(metaData, message, members)
  //           .then(() => resolve(true))
  //           .catch(e => { this.error(e, 'Failed to start conversation');reject(e)})
  //         }


  //       } else {
  //         reject("You aren't logged in.  You need to login to send a message.  ")
  //       }
  //   })
  // }
  // submitSystemConversation(metaData: ConversationMeta, message: Message, members: Array<Member>):Promise<boolean>{
  //   return new Promise((resolve, reject) => {
  //     this.afs.collection('Conversations').add(metaData)
  //     .then(docRef => {
  //       if(docRef){
  //         // add the first message
  //         this.afs.collection('Conversations').doc(`${docRef.id}`).update({key: docRef.id})
  //         this.afs.collection('Conversations').doc(`${docRef.id}`).collection('messages').add(message)
  //         for(let member of members){
  //           this.afs.collection('Conversations').doc(`${docRef.id}`).collection('members').doc(member.key).set(member)          }
  //         if(!metaData.instructor || members.length == 0){
  //           this.addUnclaimedMessage(docRef.id)
  //         }
  //         if(members.length > 0){
  //           members.forEach(member => this.incrementUnreadMessages(member.key, docRef.id))
  //         }

  //       }
  //     })
  //     .then(() => resolve(true))
  //     .catch(e => reject(e))
  //   })
  // }

  // containers

  hideLoginContainer() {
    this.loginDisplay.next(null)

  }
  hideSignUpContainer() {
    this.loginDisplay.next(null)
  }

  openLoginCloseSignUp() {
    this.loginDisplay.next('login')
  }
  openUserSelect(){
    this.loginDisplay.next('select')
  }
  
  openSignUpCloseLogin() {
    this.loginDisplay.next('signUp')
  }

  openLogin() {
    this.loginDisplay.next('login')
    return Promise.resolve();// keep this for legacy support
  }

  openSignUp() {
    this.loginDisplay.next('signUp')
  }

  getLoginDisplaySubject():Observable<string>{
    return this.loginDisplay.asObservable()
  }

  //games
  gotoGame(index){
    switch(index){
      case 1: this.router.navigate(['crossword']);break;
      default: break
    }
  }
  gotoGames(){
    this.router.navigate(['games'])
  }
}

