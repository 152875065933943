import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style , animate, transition, query, stagger, keyframes  } from '@angular/animations';
import { LessonCard } from '../../Models/lessons.interface';
// import { SimpleChange } from '@angular/core/src/change_detection/change_detection_util';
import { UserManager } from '../../Services/UserManager/user-manager';
import { LessonManagerService } from '../../Services/LessonManager/lesson-manager.service';

@Component({
  selector: 'app-lesson-header-bar',
  templateUrl: './lesson-header-bar.component.html',
  styleUrls: ['./lesson-header-bar.component.css'],
  animations: [
    // trigger('all', [
    //     state('goodHands', style({
    //         transform: 'scale(1)' ,
    //     })),
    //     state('void', style({
    //         transform: 'scale(0)' ,          
    //     })),
    //     transition('* => goodHands',[ animate('400ms') ])
    // ]),
    trigger('imageTrigger', [
        state('show', style({
            transform: 'scale(1)' ,
          
        })),
        state('void', style({
            transform: 'scale(0)' ,
            // opacity: 0
          
        })),
        transition('* => show',[
            animate('400ms',
            keyframes([
                style({ transform: 'scale(0)', offset:0.1}),
                style({ transform: 'scale(0.4)', offset:0.2}),
                style({ transform: 'scale(1.2)', offset:0.4}),
                style({ transform: 'scale(0.9)', offset:0.7}),
                style({ transform: 'scale(1)'  , offset:0.9})
            ]))
        ]),
    ]),
    trigger('backgroundTrigger', [
      state('show', style({
          transform: 'translateX(0)' ,
            opacity: 1
      })),
      state('void', style({
        transform: 'translateX(20%) scale(0)' ,
        opacity: 0
      
    })),
    transition('* => show', [animate('400ms 150ms ease-out')]),
    transition('show => *', [animate('400ms 150ms ease-out')])
    //   transition('* => show',[
    //       animate('400ms',
    //       keyframes([
    //           style({ opacity: 0, transform: 'translateX(100%) scale(1)', offset:0.2}),
    //           style({ opacity: 1, transform: 'translateX(40%)', offset:0.4}),
    //           style({ transform: 'translateX(-20%)', offset:0.7}),
    //           style({ transform: 'translateX(10%)'  , offset:0.9})
    //       ]))
    //   ]),
    //   transition('show => *',[
    //     animate('400ms',
    //     keyframes([
    //         style({ transform: 'translateX(10%)', offset:0.2}),
    //         style({ transform: 'translateX(-20%)', offset:0.4}),
    //         style({ opacity: 1 , transform: 'translateX(70%)', offset:0.7}),
    //         style({ opacity: 0, transform: 'translateX(100%) scale(0)'  , offset:0.9})
    //     ]))
    // ]),
  ])
  ]
})
export class LessonHeaderBarComponent implements OnInit {
  @Input() lessonCard: LessonCard;
  imageState = 'void';
  backgroundState = 'void';
  allState;
  
  constructor(private um: UserManager, private lm: LessonManagerService) { }

  ngOnInit() {
      this.allState = true
      this.imageState = 'show'
      this.backgroundState = 'show'
  }

  setLesson(lesson: LessonCard){
    this.allState = true
    // console.log(lesson)
    this.lessonCard = lesson
    this.imageState = 'show'
    this.backgroundState = 'show'
  }

//   loadNextPage(){
//       console.log(`lesson card is = ${this.lessonCard.id}`)
//     if(this.lessonCard){ this.um.gotoLessonHeader(this.lessonCard.id)}
//   }

}
