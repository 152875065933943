import { Injectable } from '@angular/core';
import { LessonCard} from '../../Models/lessons.interface';
@Injectable()
export class LessonManagerService {

 lessonsCards: Array<LessonCard> = [
  {
    'id': 1,
    'title': 'Buried Treasure!',
    'img': '../../../assets/Images/cover1.jpg',
    'unlocked': true,
    'topic': "Bible Validity"
  },
  {
    'id': 2,
    'title': 'Picture Perfect',
    'img': '../../../assets/Images/cover2.jpg',
    'topic': "Last Day Events"
  },
  {
    'id': 3,
    'title': 'The Greatest Invention of All Time',
    'img': '../../../assets/Images/cover3.jpg',
    'topic':"Suffering" 
},
  {
    'id': 4,
    'title': 'A Purple Heart',
    'img': '../../../assets/Images/cover4.jpg',
	  'topic':"Sin Origins"
  },
  {
    'id': 5,
    'title': 'Rescued!',
    'img': '../../../assets/Images/cover5.jpg',
	  'topic':"Heaven"
  },
  {
    'id': 6,
    'title': 'Turning Toward the Son',
    'img': '../../../assets/Images/cover6.jpg',
	  'topic':"10 Commandments"
  },
  {
    'id': 7,
    'title': 'Direct Messaging',
    'img': '../../../assets/Images/cover7.jpg',
	  'topic':"Sabbath"
  },
  {
    'id': 8,
    'title': 'Are We Almost There',
    'img': '../../../assets/Images/cover8.jpg',
	  'topic':"Sabbath"
  },
  {
    'id': 9,
    'title': 'A New Start',
    'img': '../../../assets/Images/cover9.jpg',
  	'topic':"Surrender"
  },
  {
    'id': 10,
    'title': 'Law and Order',
    'img': '../../../assets/Images/cover10.jpg',
	  'topic':"State of the Dead"
  },
  {
    'id': 11,
    'title': 'A Special Celebration',
    'img': '../../../assets/Images/cover11.jpg',
	  'topic':"Millennium"
  },
  {
    'id': 12,
    'title': 'Invisible Friends',
    'img': '../../../assets/Images/cover12.jpg',
	  'topic':"Hell"
  },
  {
    'id': 13,
    'title': 'Beyond the Tomb',
    'img': '../../../assets/Images/cover13.jpg',
	  'topic':"Second Coming"
  },
  {
    'id': 14,
    'title': 'Gone Forever',
    'img': '../../../assets/Images/cover14.jpg',
	  'topic':"Baptism"
  },
  {
    'id': 15,
    'title': 'Waiting and Watching',
    'img': '../../../assets/Images/cover15.jpg',
	  'topic':"Health"
  },
  {
    'id': 16,
    'title': 'An Incredible Dream',
    'img': '../../../assets/Images/cover16.jpg',
	  'topic':"Sanctuary"
  },
  {
    'id': 17,
    'title': 'Blueprints for Eternity',
    'img': '../../../assets/Images/cover17.jpg',
	  'topic':"Judgement"
  },
  {
    'id': 18,
    'title': 'A Recipe for Health',
    'img': '../../../assets/Images/cover18.jpg',
	  'topic':"Revelation 13"
  },
  {
    'id': 19,
    'title': 'Five Times More',
    'img': '../../../assets/Images/cover19.jpg',
	  'topic':"Mark of the Beast"
  },
  {
    'id': 20,
    'title': 'A Guide You Can Trust',
    'img': '../../../assets/Images/cover20.jpg',
	  'topic': "U.S. in Prophecy"
  },
  {
    'id': 21,
    'title': 'Beyond Imagination',
    'img': '../../../assets/Images/cover21.jpg',
	  'topic':"Church"
  }
];

  constructor() { }

  getLessonCards(): Array<LessonCard> {
    var retLessons  = JSON.parse(JSON.stringify(this.lessonsCards))   // break the reference before you return the object, aka, copy it, dont pass a reference
    return retLessons;

  }
  getCard(id: number): LessonCard {
    if (id > 0 && !(id > this.lessonsCards.length)) {
       return this.lessonsCards[id - 1]
    }
    return null
  }




}

