import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crossword-top-background',
  templateUrl: './crossword-top-background.component.html',
  styleUrls: ['./crossword-top-background.component.css']
})
export class CrosswordTopBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
