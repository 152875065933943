import { Component, OnInit } from '@angular/core';
import { LessonCard } from './../../Models/lessons.interface';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import { trigger, state, transition, animate, style} from '@angular/animations';
@Component({
  selector: 'app-my-lessons',
  templateUrl: './my-lessons.component.html',
  styleUrls: ['./my-lessons.component.css'],
  animations: [
    trigger('title', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('400ms ease-in-out')]),
        transition('* => void',[ animate('400ms ease-in-out')]),
  ])]
})
export class MyLessonsComponent implements OnInit {
choosenLessonCard: LessonCard;
  currentState = 'show'
  constructor(
              private scroll: ScrollService) { 
  }

  ngOnInit() {
    this.scroll.scrollToElement('#logo')
  }

  toggleLessonHeaderBar(lessonCard: LessonCard){
    this.choosenLessonCard = lessonCard
  }

  sendTitleOut(){
    this.currentState = 'void'
  }

}
