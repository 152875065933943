import { Component , Pipe, PipeTransform,} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {trigger, transition, animate, style} from '@angular/animations'
import { UserManager } from './Services/UserManager/user-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
        'item', [
          transition(':enter', [
            style({ opacity: 0}),
            animate('300ms', style({ opacity: 1}))
          ]),
          transition(':leave', [
            style({ opacity: 1}),
            animate('100ms', style({ opacity: 0}))
          ])
        ])
    ]
})

export class AppComponent {

  display;
  constructor(private um: UserManager){
    localStorage.removeItem('firebase:previous_websocket_failure');
    this.um.getLoginDisplaySubject().subscribe(display => this.display = display )
  }
  title = 'app';
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

// Epochs
const epochs: any = [
  ['year', 31536000],
  ['month', 2592000],
  ['day', 86400],
  ['hour', 3600],
  ['minute', 60],
  ['second', 1]
];

const shortEpochs: any = [
  ['yr', 31536000],
  ['mo', 2592000],
  ['d', 86400],
  ['hr', 3600],
  ['min', 60],
  ['s', 1]
];

/*
from https://gist.github.com/JonCatmull/e00afb1c96298a4e386ea1b5d091702a
*/
@Pipe({name: 'relativeDate'})
export class RelativeDatePipe implements PipeTransform {

  getDuration(timeAgoInSeconds: number, short: any = null) {
    if(short){
      for (let [name, seconds] of shortEpochs) {
        let interval = Math.floor(timeAgoInSeconds / seconds);

        if (interval >= 1) {
            return {
                interval: interval,
                epoch: name
            };
        }
    }
    return {
        interval: 0,
        epoch: 's'
    };
    }else {
      for (let [name, seconds] of epochs) {
          let interval = Math.floor(timeAgoInSeconds / seconds);

          if (interval >= 1) {
              return {
                  interval: interval,
                  epoch: name
              };
          }
      }
      return {
          interval: 0,
          epoch: 's'
      };

    }
  };

  transform(dateStamp: string, short: boolean = false): string {
    
    if(short){
      let timeAgoInSeconds = Math.floor((new Date().getTime() - new Date(+dateStamp).getTime()) / 1000);
      let {interval, epoch} = this.getDuration(timeAgoInSeconds, short);

      return `${interval} ${epoch}`;
    } else {
      let timeAgoInSeconds = Math.floor((new Date().getTime() - new Date(+dateStamp).getTime()) / 1000);
      let {interval, epoch} = this.getDuration(timeAgoInSeconds);
      let suffix =  (interval === 1) ? '' : 's';

      return `${interval} ${epoch}${suffix} ago`;
    }
  }

}

@Pipe ({name: 'firstLetter'})
export class FirstLetterPipe implements PipeTransform {

  transform(letters: string): string{
    if(!letters){return ""}
    return letters.charAt(0).toUpperCase()
  }
}
@Pipe ({name: 'cap'})
export class CapatalizePipe implements PipeTransform {

  transform(letters: string): string{
    if(!letters || letters.length === 0){return ""}
    return letters[0].toUpperCase() + letters.substring(1,letters.length)
  }
}

@Pipe ({name: 'otherLetters'})
export class OtherLettersPipe implements PipeTransform {

  transform(letters: string): string{
    if(!letters){return ""}
    return letters.slice(0,1)
  }
}
@Pipe ({name: 'newline'})
export class NewlinePipe implements PipeTransform {

  transform(text: string): string{
    if(!text){return ""}
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    text = text.replace(/--/g, '&mdash;')
    return text
  }
}

