<div class="background center" [@item]>
    <div >
        <div 
          class="size"
          >
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
        </div>
    </div>
    <h1>Congratulations!</h1>
    <h2>You finished lesson {{lesson}}</h2>


</div>
