import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../Services/UserManager/user-manager';

@Component({
  selector: 'app-start-study',
  templateUrl: './start-study.component.html',
  styleUrls: ['./start-study.component.scss']
})
export class StartStudyComponent implements OnInit {

  constructor(private um: UserManager) { }

  ngOnInit() {
  }
  start() {
    this.um.gotoLessonHeader(1)
  }
}
