import { Component, OnInit } from '@angular/core';
// import { Ng2DeviceService } from 'ng2-device-detector';

@Component({
  selector: 'app-browser-checker',
  templateUrl: './browser-checker.component.html',
  styleUrls: ['./browser-checker.component.css']
})
export class BrowserCheckerComponent implements OnInit {
 old;
 message;
 notChrome;
  constructor(
    // private device: Ng2DeviceService
    ) { }

  ngOnInit() {
    // this.browserCheck()
  } 
  
  browserCheck(){
    
      // const info = this.device.getDeviceInfo()
      // console.log(info.browser, info.browser_version)


      // if(info.browser != 'chrome'){
      //   this.notChrome = true
      // }
      
      // switch(info.browser){
      //   case "safari": {if(+info.browser_version < 10){this.old = true}; break }
      //   case "chrome": { if(+info.browser_version.split('.')[0] < 63){this.old = true} ;break }
      //   case "ms-edge": {if(+info.browser_version < 14){this.old = true} ; break }
      //   case "opera": {if(+info.browser_version.split('.')[0] < 50){this.old = true} ; break }
      //   case "firefox": {if(+info.browser_version < 60){this.old = true} ; break }
      //   default :  {this.old = true}
      // }


  }

}
