import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserManager } from '../../Services/UserManager/user-manager';
import { trigger, transition, style, animate} from '@angular/animations'
@Component({
  selector: 'app-cross-word',
  templateUrl: './cross-word.component.html',
  styleUrls: ['./cross-word.component.scss'],
  animations: [
    trigger(
      'item', [
        transition(':enter', [
          style({opacity: 0}),
          animate('300ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('100ms', style({ opacity: 0}))
        ])
      ])
]
})
export class CrossWordComponent implements OnInit {
  // @ViewChild('crosswordinput1') input: ElementRef;
  selectedCell: Cell
  selectedParent: Parent
  matrix;
  hint = "Click a blank for the hint"
  cellSize;
  numberOfCells = 13;
  screenHeight;
  screenWidth;
  letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  parentSelectedSubject = new BehaviorSubject<Parent>(null)
  cellSubject = new BehaviorSubject<Cell>(null)
  cellShouldUpdate = new BehaviorSubject<null>(null)
  parents = [
    new parent( 0, 3, 12, 3, 'UNDERSTANDING', this.parentSelectedSubject, (hint) => this.hint = hint, 'What the Bible gives to the simple (Psalm 119:130)'),
    new parent( 3, 1, 3, 5, 'EVERY', this.parentSelectedSubject, (hint) => this.hint = hint, 'The amount of Bible words we are to live by (Matthew 4:4)'),
    new parent( 0, 5, 3, 5, 'PRAY', this.parentSelectedSubject, (hint) => this.hint = hint, 'What we should do before reading the Bible.'),
    new parent( 2, 11, 8, 11, 'FOREVER', this.parentSelectedSubject, (hint) => this.hint = hint, 'God\'s word stands _______ (Isaiah 40:8)'),
    new parent( 2, 5, 2, 7, 'ALL', this.parentSelectedSubject, (hint) => this.hint = hint, 'The amount of truth the Holy Spirit guides us to ( John 16:13)'),
    new parent( 7, 0, 7, 4, 'CLEAN', this.parentSelectedSubject, (hint) => this.hint = hint, 'Living by the Bible makes our lives ______ (Psalm 119:9)'),
    new parent( 5, 3, 5, 11, 'SCRIPTURE', this.parentSelectedSubject, (hint) => this.hint = hint, 'All __________ is inspired by God (2 Timothy 3:16)'),
    new parent( 3, 9, 3, 12, 'FOOD', this.parentSelectedSubject, (hint) => this.hint = hint, 'The Bible is more necessary than ______ (Job 23:12)'),
    new parent( 10, 1, 10, 5, 'DAILY', this.parentSelectedSubject, (hint) => this.hint = hint, 'How often we should study the Bible (Acts 17:11)'),
    new parent( 12, 3, 12, 5, 'GOD', this.parentSelectedSubject, (hint) => this.hint = hint, 'There is no other ______(Isaiah 46:9)'),
    new parent( 7, 8, 7, 11, 'LOVE', this.parentSelectedSubject, (hint) => this.hint = hint, 'The Bible tells us God is _____ (1 John 4:8)'),
  ]
  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.keyCode === 46 || event.keyCode === 8){
      this.updateCell('')
    }  
    else if(this.letters.indexOf(event.key) >= 0){
        this.updateCell(event.key)
      }
      
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    const vertical = this.screenHeight > this.screenWidth ? true : false;
    var width;
    if(vertical){
      width = 90;
    } else {
      width = 40;
    }
    this.cellSize = `${width / this.matrix.length}vw`

  }

  constructor(private um: UserManager) { }
  updateHint(hint) {
    this.hint = hint
  }
  ngOnInit() {
    this.matrix = this.generate(this.numberOfCells)
    this.parents.forEach(parent => {
      parent.getCells().forEach(cellId => { 
        var cell = this.matrix[cellId.y][cellId.x]
        cell.addParent(parent)
      }) 
    })

    this.parentSelectedSubject.subscribe(p => this.selectedParent = p)
    this.cellSubject.subscribe(cell => {
      this.selectedCell = cell
    })
    this.onResize()
  }
  focus(event){
    // console.log(event)
  }

  updateCell = (value) => {
    if(!this.selectedCell){return}
    this.selectedCell.update(value)
    this.selectNextCell()

  }
  selectNextCell(){  
    
    if(!this.selectedParent || this.selectedParent.id === '') {return}
    const next = this.selectedParent.getNextChild(this.selectedCell.x, this.selectedCell.y)
    var instance = next && next.y && next.x ? this.matrix[next.y][next.x] : null
    if(instance){instance.selectCell(true)} // pass true so that parent doens't toggle.
    else if(this.selectedCell.parents.length === 2) {
      // if the cell has another parent, enable that parent
      this.selectedCell.selectCell()
      this.selectNextCell()
    } 

  }


  private generate(num) {
    return new Array(num).fill('').map((_, y) => {
      var row = new Array(num).fill('').map((_ , x) => new cell(x, y, this.cellSubject, this.parentSelectedSubject, this.cellShouldUpdate))
      return row
      });
  };
  getParentSubject = () => this.parentSelectedSubject
  getCellSubject = () => this.cellSubject
  

  gotoNextLesson() {
    this.um.getCurrentLesson()
    .then(number => this.um.gotoLessonHeader(number))
    .catch(_ => this.um.gotoLessonHeader(1))
  }
  gotoGames(){
    this.um.gotoGames()
  }
}

class parent {
  startX: number
  endX: number
  startY: number
  endY: number
  letters: string[]
  hint: string
  selected = false
  id: string
  cells = Array<{x: number, y: number}>()
  answer: Array<string>
  correct: Boolean = false
  updateHint: Function
  parentSubject: BehaviorSubject<Parent>
  constructor(
    startX: number,
    startY: number,
    endX: number,
    endY:number,
    letters: string,
    parentSubject: BehaviorSubject<Parent>,
    showHint: Function,
    hint: string,

    ) {
      this.startX = startX
      this.endX = endX
      this.startY = startY
      this.endY = endY
      this.updateHint = showHint
      this.id = `XA${this.startX}-YA${this.startY}-XB${this.endX}-YB${this.endY}`
      this.letters = letters.split('')
      this.answer = Array(this.letters.length).fill('')
      this.parentSubject = parentSubject
      this.parentSubject.subscribe(parent => {
        if(parent){
          this.selected = parent.id === this.id ? true :  false}
        }
      )
      
      this.hint = hint
      if(this.startX === this.endX){
        for(let i = this.startY; i <= this.endY; i++ ){
          this.cells.push({x: this.startX, y: i})
        }
      } else if (this.startY === this.endY){
        for(let i = this.startX; i <= this.endX; i++ ){
          this.cells.push({x: i, y:this.startY})
        }
      } else {
        console.error(`Could not decipher parent with letters 
        ${this.letters} and  X0 ${this.startX},
        Y0 ${this.startY}, X1 ${this.endX}, Y1 ${this.endY}.  
        Make sure either startX === endX or startY === endY`)
      }
  }
  getCells = () => this.cells
  getNextChild(x, y){
    var found = false
    for(let cell of this.cells){
      if(found){return {x: cell.x, y: cell.y}}
      if(cell.x === x && cell.y === y){
        // find the current cell and return next one 
        found = true
        
      }
    }
    return null
  }
  showHint() { console.log(this.hint)}
  select() {
    this.selected = true;
    this.updateHint(this.hint)
    }
  deselect() {this.selected = false}
  update(x: number, y: number, value: string):Promise<Boolean> {
    
    if(this.startX === this.endX){
      if(y >= this.startY && y <= this.endY){
        // console.log(this.startY, y, value)
        this.answer[Math.abs(this.startY - y)] = value
        // console.log(this.answer, this.letters)
      }
    } else if(this.startY === this.endY){
      // console.log(x, this.startX, this.endX)
      if(x >= this.startX && x <=this.endX){
        this.answer[Math.abs(this.startX - x)] = value
        // console.log(this.answer, this.letters)
      }
    }
    return this.validate()
  }
  validate(): Promise<Boolean>{
    return new Promise((resolve) => {
      this.correct = this.letters.join('').toLowerCase() === this.answer.join('').toLowerCase() ? true : false
      
      resolve(this.correct)
    })

  }
}

class cell implements Cell{
  x: number
  y: number
  id: string
  value: string;
  parents = Array<Parent>()
  selected: Boolean = false
  selectedParent: Parent
  correct = false
  cellSubject: BehaviorSubject<Cell>
  parentSubject: BehaviorSubject<Parent>
  shouldUpdate: BehaviorSubject<null>
  constructor(x: number, y: number, cellSubject, parentSubject, shouldUpdate) {
    this.x = x
    this.y = y
    this.id = `${x}-${y}`
    this.cellSubject = cellSubject
    this.parentSubject = parentSubject
    this.shouldUpdate = shouldUpdate
    if(this.shouldUpdate){
      this.shouldUpdate.subscribe(_ => this.checkIfParentCorrect())
    }
    this.cellSubject.subscribe(cell => {
      if(cell){
        this.selected = cell.id === this.id ? this.selected = true : false
      } else {
        this.selected = false
      }
    })
    this.parentSubject.subscribe(parent => {
      if(!parent) { this.selectedParent = null ;return}
      for(let p of this.parents){
        if(p.id === parent.id){
          this.selectedParent = parent
          break
        } 
        this.selectedParent = null
      }
    })
  }
 
  checkIfParentCorrect() {
    var results = false;
    this.parents.forEach(parent => {
      parent.update(this.x, this.y, this.value)
      .then(result => {
        results = result !== null ? results || result : false 
        this.correct = results
        
      })
      .catch(e => console.error(e))
    })
  }
  addParent = (parent: Parent) => this.parents.push(parent)
  removeParent = (parent: Parent) => this.parents.filter(p => parent.id !==  p.id)
  update = (value) => {
    this.value = value
    var results = false;
    this.parents.forEach(parent => {
      parent.update(this.x, this.y, this.value)
      .then(result => {
        results = result ? results || result : false 
        this.correct = results
      })
      .catch(e => console.error(e))
    })
    this.shouldUpdate.next(null)
    
  }
  selectCell(shouldntChange: Boolean = false, e) {
    // console.log(e)
    // this will work with 2 parents, but not more
    if(this.parents.length === 0){
      this.parentSubject.next(null)
      this.cellSubject.next(null)
      // document.getElementById('in').blur()
      return 
    }
    this.cellSubject.next(this)
    // document.getElementById('in').focus()
    if(shouldntChange){return} // don't switch parents
    for (let parent of this.parents){
      
      if(!this.selectedParent ){
        this.selectedParent = this.parents[0]
        this.selectedParent.select(this.id)
        this.parentSubject.next(this.selectedParent)
        break
      }
      else if(parent.id !== this.selectedParent.id) { 
        this.selectedParent = parent
        this.selected = true
        this.selectedParent.select(this.id)
        this.parentSubject.next(this.selectedParent)
        break
      }
    }
  }
}

interface Parent {
  id: string,
  select: Function
  update: Function
  getNextChild?: Function
}
interface Cell {
  id: string,
  x?: number,
  y?: number,
  update: Function,
  selectedParent?: Parent
  selectCell?: Function
  parents?: Parent[]
}