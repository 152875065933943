
  <div class=insets [@item]>
    <div class=outer >
        
      <div class=inner *ngFor="let row of matrix" >
        
        <div 
        class=cell 
        *ngFor="let cell of row"
        (click)="cell.selectCell(false,$event)"
        [ngClass]="
          {'selected': selectedCell?.id === cell?.id ,
          'has-parent': 
            cell?.parents?.length > 0 
            && !cell?.selectedParent?.id 
            && !cell?.correct
            &&  selectedCell?.id !== cell?.id,
          'parentSelected': 
            cell?.selectedParent?.id  
            && !cell?.correct
            &&  selectedCell?.id !== cell?.id,
          'parentCorrectSelected':
            cell?.selectedParent?.id
            && cell?.correct
            && selectedCell?.id !== cell?.id,
          'correct': 
            cell?.correct 
            && !cell?.selectedParent?.id
            && selectedCell?.id !== cell?.id
          }"
        [ngStyle]="{'width': cellSize, 'height': cellSize}"
        >
        <span *ngIf="cell?.parents?.length > 0">{{cell?.value?.toUpperCase()}}</span>
        <input *ngIf="cell?.parents?.length > 0 "  class=input style="caret-color: transparent" />
        
        </div>
      </div>
      <div class=center>
          <span class=hint>{{hint}}</span>
      </div>
      <div class=navButtons style="margin-bottom: 20px;">
        <div class="dbutton button" (click)=gotoGames()>Games</div>
        <div class="dbutton button" (click)="gotoNextLesson()">Next Lesson</div>
      </div>



      <div class=flex>
        <app-crossword-top-background class=t></app-crossword-top-background>
    </div>
    <div class=b>
      <app-crossword-bottom-background></app-crossword-bottom-background>
      <div class=bottom></div>
    </div>
    
  </div>
  </div>

