import { UserManager } from './../../Services/UserManager/user-manager';
import { LessonCard } from './../../Models/lessons.interface';
import { LessonManagerService } from './../../Services/LessonManager/lesson-manager.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVariablesService } from '../../Services/globalVariables/global-variables.service';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
// import { trigger, state, style , animate, transition, query, stagger  } from '@angular/animations';


@Component({
  selector: 'app-lessons-flat',
  templateUrl: './lessons-flat.component.html',
  styleUrls: ['./lessons-flat.component.css', /*'./lessonPhotos.css',*/ '../../app.component.scss'],
  providers: [LessonManagerService]
})
export class LessonsFlatComponent implements OnInit {
  count = 1
  lessonCards: Array<LessonCard>;
  lessonIDs = Array<string>();
  // lessonsList$: FirebaseListObservable<LessonCard[]>;

  constructor( private lsnManager: LessonManagerService,
     private router: Router,
      public um: UserManager,
      private gv: GlobalVariablesService,
      private scroll: ScrollService) { 
    this.lessonCards = this.lsnManager.getLessonCards();
        this.lessonIDs = []
        for (let lesson of this.lessonCards){
          this.lessonIDs.push(`flatLesson${lesson.id}`)
          // console.log(lesson.id)
          

        }

  }

  
  ngAfterViewChecked(){
    
    
  }

  ngOnInit() {
  }

  showLoginScreen() {
    this.um.openLogin()
  }
  scrollRight(){

    

    if (this.count <= 20 ){ this.count += 5;
       this.scroll.scrollHorizontalInContainer('#flatLessonContainer', `#flatLesson${this.count}`) }
      //  console.log(this.count)

  }
  scrollLeft(){
    // console.log(this.count)
    if (this.count >=4  ) { 
      this.count -= 5 ;
      this.scroll.scrollHorizontalInContainer('#flatLessonContainer', `#flatLesson${this.count}`)}
    else { this.scroll.scrollHorizontalInContainer('#flatLessonContainer', `#flatLesson1`)}
  }



  chooseLesson(lessonCard: LessonCard) {
    if (lessonCard.id > 2) {
      // make sure user profile is not annonymous.  If so, they need to sign up, if not, they need to login.
        // this.gv.print(this.um.isLoggedIn());
        if (this.um.isLoggedIn()) {
          this.um.gotoLessonHeader(lessonCard.id)
          // this.router.navigate(['/lesson-header', {id: lessonCard.id, title: lessonCard.title, img: lessonCard.img}]);
          return;
        } else {
          // display message telling user to login.
          this.showLoginScreen();
          return;
         }
    } else {
      this.um.gotoLessonHeader(lessonCard.id)
      // this.router.navigate(['/lesson-header', {id: lessonCard.id, title: lessonCard.title, img: lessonCard.img}]);
    } 
  }
}


