import { Component, OnInit } from '@angular/core';
import { trigger, state, style , animate, transition } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('trigger', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('400ms ease-in-out')]),
        transition('* => void',[ animate('400ms ease-in-out')]),
  ])]
})

export class HomeComponent implements OnInit {
  currentState = 'void'
  constructor() { this.currentState = 'show'}

  ngOnInit() {
  }

  show() {
    const signUpComponent = document.getElementById('signUpContainer');
    signUpComponent.classList.remove('hide');
  }
}
