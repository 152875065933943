
  <app-lesson-header-bar *ngIf="lessonCard" [lessonCard]=lessonCard ></app-lesson-header-bar>
  <div [@all]=currentState (@all.done)="next($event)">
      <div *ngIf="currentState !== 'void'">
        <div id='lesson1Heading' class="welcomeContainer transition" *ngIf="id === '1'" >
            <h1 class="welcomeHeader inset transition">Welcome</h1>
            <p class="welcomeBody inset transition">{{welcomeMessage}}</p>
        </div>
        <div class="background transition" >
            <div class="inset">
              <div class="container transition">
                  <div class='desktopHide mobile transition'>
                        

                  </div>
                  <div id="LessonIntro" class="leftSection transition mobileHide">
                    <h3 style="color: #E09A00;">INTRODUCTION</h3>
                    <p *ngIf="intro" style="text-align: justify;" [innerHtml]="intro?.body | newline">
                    </p>

                <div class="mobileHide transition flex">
                    <!-- <p *ngIf="anonymous && unlocked" class="anonymousWarning ">
                    You are not signed up, and therefore only have access to Guides 1 and 2. 
                    If you navigate away from the site, your work will be lost. Sign Up to 
                    avoid losing your work, or Login if you already have an account.  </p>
                    <button 
                    *ngIf="anonymous && unlocked" 
                    class="anonymousButton mobileHide transition" 
                    (click)="um.openSignUp()"
                    >Sign Up</button>
                    <button *ngIf="anonymous && unlocked" class="dbutton mobileHide transition login" (click)="um.openLogin()" >Login</button> -->
                    
                    
                    <!-- <p *ngIf="!unlocked" class="anonymousWarning mobileHide">Looks like you haven't unlocked this lesson.  Complete Lesson {{previousUnlocked}} to unlock this one.</p> -->
                    <!-- <button  *ngIf="!unlocked && !anonymous" class="dbutton mobileHide transition" (click)="gotoLessonHeader(previousUnlocked)" >Go back to Guide {{previousUnlocked}}</button> -->
                    <!-- <button  *ngIf="!unlocked && anonymous" class="dbutton mobileHide transition" (click)="gotoLessonHeader(previousUnlocked)" >Go back to Guide {{previousUnlocked}}</button> -->
                    
                    <button  *ngIf="unlocked" class="dbutton mobileHide transition" (click)="gotoLesson()" >Let's Get Started</button>
                    <!-- <button  *ngIf="unlocked && anonymous" class="dbutton mobileHide transition" (click)="gotoLesson()" >Continue Anyway</button> -->
                </div>


                </div>

                  <div class="rightSection transition">
                      <!-- <iframe class="video" 
                        width="400" 
                        height="300" 
                        [src]="video | safe" 
                        frameborder="0" 
                        allow=" encrypted-media" 
                        webkitallowfullscreen 
                        mozallowfullscreen 
                        allowfullscreen>

                      </iframe> -->
                    <div class=desktopHide>
                        <h3 style="color: #E09A00;">INTRODUCTION</h3>
                        <p *ngIf="intro" style="text-align: justify;" [innerHtml]="intro?.body | newline"></p>
                    </div>
                      <img class="image transition" src="../../../../assets/Images/lessonIntro/lesson{{id}}.jpg" >

                  </div>
                <div class="desktopHide mobile transition">

                  <!-- <button *ngIf="anonymous && unlocked" class="anonymousButton  transition" (click)="um.openSignUp()" >Sign Up</button> -->
                  <!-- <button *ngIf="anonymous && unlocked" class="mobileButtonConfig  transition" (click)="um.openLogin()" >Login</button> -->
                  
                  <!-- <p *ngIf="!unlocked" class="anonymousWarning">Looks like you haven't unlocked this lesson.  Complete Lesson {{previousUnlocked}} to unlock this one.</p> -->
                  <!-- <button  *ngIf="!unlocked && !anonymous" class="mobileButtonConfig  transition" (click)="gotoLessonHeader(previousUnlocked)" >Go back to Guide {{previousUnlocked}}</button> -->
                  <!-- <button  *ngIf="!unlocked && anonymous" class="mobileButtonConfig  transition" (click)="gotoLessonHeader(previousUnlocked)" >Go back to Guide {{previousUnlocked}}</button> -->
                  
                  <button  *ngIf="unlocked && profile" class="mobileButtonConfig  transition" (click)="gotoLesson()" >Let's Get Started</button>
                  <!-- <button  *ngIf="unlocked && anonymous && profile" class="mobileButtonConfig  transition" (click)="gotoLesson()" >Continue Anyway</button> -->
                </div>

            </div>
      </div>
  </div>
</div>
    