import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../Services/UserManager/user-manager';
import {instruction} from '../../Models/instruction.interface';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
  instructions: Array<instruction> = [
    {
      'text': 'The following slides are here to help you learn the website better. Click \'Continue\' to begin the tour. ',
    },
    {
      'text': 'Tap the correct answer to select it, then tap the appropriate blank.  To remove an answer, double tap it to return it to the word cloud.',
      'img': '../../assets/Images/add-remove-click.gif'
    },
    {
      'text': 'Alternatively, drag the answer to the desired location.   ',
      'img': '../../assets/Images/add-remove-drag.gif'
    },
    {
      'text': 'Tap the red flag to reveal the Bible Verse associated with the question.  Click the "X" or anywhere outside of the verse box to dismiss.',
      'img': '../../assets/Images/verse.gif'
    },
    {
      'text': 'A green checkmark will appear when a question has been completed correctly.  All questions must be completed correctly to continue on to the next lesson.',
      'img': '../../assets/Images/complete.gif'
    },
    {
      'text': 'Use the navigation dots at the top of the page to move between questions, or use the "Continue" and "Go Back" buttons (or arrow buttons) at the bottom of the page.',
      'img': '../../assets/Images/navigate.gif'
    }
  ]

  index = 0
  lesson;
  constructor(
    private route: ActivatedRoute,
    private um: UserManager
    ) { 

    this.lesson = +this.route.snapshot.paramMap.get('lesson');
  }

  ngOnInit() {

  }

  next(){
    if(this.index < this.instructions.length -1){
      this.index += 1
    } else {
       // you are at the end, go to the lesson
       this.gotoLesson()
    }
  }
  previous(){
    if(this.index >= 1){
      this.index-=1
    }
  }
  gotoLesson(){
    this.um.gotoLesson(this.lesson, null)
    // .catch(e => console.error(e))
    
    
  }

}

