import { UserManager } from './../../Services/UserManager/user-manager';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, ViewChildren , QueryList, HostListener} from '@angular/core';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { AngularFireDatabase} from '@angular/fire/database';
import { Question } from '../../Models/question.interface';
import { LessonManagerService } from '../../Services/LessonManager/lesson-manager.service';
import { LessonCard } from '../../Models/lessons.interface';
import { QuestionTemplateComponent} from '../../templates/question-template/question-template.component'
import { GlobalVariablesService } from '../../Services/globalVariables/global-variables.service';
import { Location } from '@angular/common';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import {Observable} from 'rxjs/Observable'
import {trigger, state, style, transition, animate} from '@angular/animations'
import * as filesaver from 'file-saver'
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}


@Component({
  selector: 'app-lesson-template',
  templateUrl: './lesson-template.component.html',
  styleUrls: ['./lesson-template.component.scss','./svg.scss'],
  animations: [
    trigger('trigger', [
        state('show', style({
            opacity: 1,
        })),
        state('void', style({
          opacity: 0
        })),
        transition('void => show', animate('400ms 500ms ease-in-out')),
        transition('show => void', animate('400ms 500ms ease-in-out')),
      ]),
    trigger(
        'item', [
          transition(':enter', [
            style({height: 0, transform: 'scale(0)', opacity: 0}),
            animate('300ms', style({height: '*', transform: 'scale(1)', opacity: 1}))
          ]),
          transition(':leave', [
            style({height: '*', transform: 'scale(1)', opacity: 1}),
            animate('100ms', style({height: 0, transform: 'scale(0)', opacity: 0}))
          ])
        ])
  ]

})
export class LessonTemplateComponent implements OnInit {
  title: string;
  img: string;
  viewChecked = false;
  lessonCard: LessonCard;
  lessonID: string;
  questionID: string;
  currentQuestionID: number = 1;
  currentQuestionCorrect: boolean = false;
  questions: Array<Question>;
  questions$: Observable<Question[]>;
  questionsSubscription: Subscription;
  currentState = 'void'
  activity;
  activityImage;

  allQuestionCorrectCount: number = 0;
  allQuestionCorrect: boolean = false;
  hasNextQuestion: boolean;
  hasPreviousQuestion: boolean;
  incomplete = {}
  firstIncomplete: number;

  @ViewChildren(QuestionTemplateComponent) questionElements: QueryList<QuestionTemplateComponent>;
  loaded = false;
  questionElementsSubscription: Subscription;
  stateArray = Array<string>();

  constructor(private route: ActivatedRoute,
               private router: Router,
               private um: UserManager,
               private lm: LessonManagerService,
               private db: AngularFireDatabase,
               private gv: GlobalVariablesService,
               private lc: Location,
               private scroll: ScrollService
               
               ) { }
               
  ngOnInit() {
    const id  = +this.route.snapshot.paramMap.get('id');
    this.lessonID = this.route.snapshot.paramMap.get('id');
    const lessonCard = this.lm.getCard(id);
    this.lessonCard = lessonCard;
    this.activity = `../../../assets/Images/Activity${this.lessonID}.pdf`
    this.activityImage = `../../../assets/Images/Activity${this.lessonID}.png`
    this.questions$ = 
      this.db.list<Question>(
        `Questions/${lessonCard.id}`,
        ref => ref.orderByChild('questionID'))
        .valueChanges(); 

    this.questionsSubscription = this.questions$.subscribe(questions => {
      this.questions = questions; 
      questions.forEach(q => this.stateArray.push('hideRight'))
      this.gv.print(questions.length);
      // this.scrollToQuestion() 
    });
    this.lc.replaceState(`/lesson-header;id=${this.lessonID}`)
    this.currentState = 'show'
  }

  markIncomplete(lessonNumber: number){
    this.incomplete[`${lessonNumber}`] = lessonNumber
    // console.log(this.incomplete)
    this.updateFirstIncomplete()
    this.checkIfAllCorrect()
  }

  markComplete(lessonNumber: number){
    if(this.incomplete[`${lessonNumber}`]){
      delete this.incomplete[`${lessonNumber}`]
    }
    // console.log(this.incomplete)
    this.checkIfAllCorrect()
    this.updateFirstIncomplete()
  }

  updateFirstIncomplete(){
    const keys = Object.keys(this.incomplete) 
    if(keys.length > 0){
      // console.log(keys)
      const lessonToGoTo = this.incomplete[`${keys[0]}`]
      this.firstIncomplete = lessonToGoTo
    }
  }
  
  ngOnDestroy() {
    this.deactivate(this.currentQuestionID)
    if (this.questionsSubscription) {
      this.questionsSubscription.unsubscribe();  
    }
    if(this.questionElementsSubscription) {
      this.questionElementsSubscription.unsubscribe();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log(event);
    
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
    
       if(this.hasNextQuestion){  this.goForward()}
      //  if(this.allQuestionCorrect && !this.hasNextQuestion){this.gotoNextLesson()}
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      if(this.hasPreviousQuestion){ this.goBack()}
    }
  }

  
  ngAfterViewInit() {
    this.questionElementsSubscription = this.questionElements.changes.subscribe(change => {
      if( this.questionElements && this.questions){
        this.um.getCurrentQuestion(this.lessonCard.id).then(questionID =>{
          if(questionID){
            this.updateNextAndPrevious(questionID)
            if (this.questionElements.length === this.questions.length) {
              if (!this.loaded) {
                this.loaded = true;
                this.currentQuestionID = questionID
                change.forEach(question => { 
                  if (this.currentQuestionID != question.questionID) {                
                    this.toggleOnOff(question.questionID);
                  }
                  if (this.currentQuestionID === question.questionID){
                      this.activate(question.questionID)
                      this.currentQuestionCorrect = question.allCorrect
                      this.checkIfAllCorrect()  
                    }
                })
              }
            }
          }
        })
      } 
    }) 
  }
  count = 0
  cycle(): number{
    this.count +=1
    return this.count
  }

  unhide(){    
    this.um.getCurrentQuestion(this.lessonCard.id).then(questionID => {
      if (questionID) {
        this.currentQuestionID = questionID
        const currentQuestionID = this.questionElements.find((question) => {
          return this.currentQuestionID === +question.questionID;
        })
        if (currentQuestionID) {
          currentQuestionID.activate()
          .then(_ => {
            document.getElementById(this.currentQuestionID.toString())
        });
        }
      } else {
        this.gv.print("no question ID found")
      }
    }).catch(e => { document.getElementById(this.currentQuestionID.toString()).classList.remove('hide');})
  }

  scrollToQuestion(){
    this.scroll.scrollToElement(`#dots`)
  }
  
  isCorrect(questionID: number) {
   var q =  this.questionElements.find((question) => {
      return questionID === +question.questionID ;
    })
    if (q) {
      return q.allCorrect
    } else {
      return false
    }
  }

  toggleOnOff(questionID: number){
    const aQuestion = this.questionElements.find((question) => {
      return questionID === +question.questionID ;
    })
    if (aQuestion) {
      aQuestion.toggleOnOff();
      
    }
  }

  activate(questionID: number, direction?: string){
    this.um.saveCurrentQuestion(+this.lessonID, questionID)
    const nextQuestion = this.questionElements.find((question) => {
      return questionID === +question.questionID ;
    })
    if (nextQuestion) {
      nextQuestion.activate(direction)
      .then(() => document.getElementById(`${questionID}`).classList.remove('hide')  )
      .catch(e => console.error(e))
    }
  }

  deactivate(questionID: number, direction?: string){
    const nextQuestion = this.questionElements.find((question) => {
      return questionID === +question.questionID ;
    })
    if (nextQuestion) {
      nextQuestion.deactivate(direction);
    }
  }

  updateCurrentQuestion(questionID: number) {
    document.getElementById(this.currentQuestionID.toString()).classList.add('hide');
    this.currentQuestionID = questionID
    const newCurrentQuestion = this.questionElements.find((question) => {
      return questionID === +question.questionID  
    })

    if (newCurrentQuestion) {
      this.currentQuestionCorrect = newCurrentQuestion.allCorrect
    }
    
    // this.um.saveCurrentQuestion(+this.lessonCard.id, +this.currentQuestionID);
    this.updateNextAndPrevious(+this.currentQuestionID);
  }

  updateNextAndPrevious(questionID: number){
    if ( !this.questionElements){return}
    const nextQuestion = this.questionElements.find((question) => {
      return questionID + 1=== +question.questionID  ;
    })
    const previousQuestion = this.questionElements.find((question) => {
      return  (questionID - 1 === +question.questionID && (questionID - 1) !== 0);
    })
    this.hasNextQuestion = nextQuestion ? true : false
    this.hasPreviousQuestion = previousQuestion ? true : false
  }

  checkIfAllCorrect(){
    this.allQuestionCorrectCount = 0
    this.allQuestionCorrect = false
    this.questionElements.forEach( question => {
      if (question.allCorrect){
        this.allQuestionCorrectCount +=1
        if (this.allQuestionCorrectCount === this.questions.length - 1){
          this.allQuestionCorrect = true
          this.um.unlockLesson(+this.lessonID+1)
        }
      }
    })
  }

  back(){
    this.activate(this.currentQuestionID -1, 'hideLeft')
    this.deactivate(+this.currentQuestionID, 'hideRight')
    this.backFinished();
  }
  backFinished() {
    document.getElementById(this.currentQuestionID.toString()).classList.add('hide');
    this.updateCurrentQuestion(this.currentQuestionID - 1);
  }

  forward() {
      this.activate(this.currentQuestionID +1, 'hideRight')
      this.deactivate(+this.currentQuestionID, 'hideLeft')
    // deactivate current question subscriptions
    this.forwardFinished() 
  }

  forwardFinished() {
    document.getElementById(this.currentQuestionID.toString()).classList.add('hide');
    this.updateCurrentQuestion(this.currentQuestionID + 1);
  }
  hit = false
  goBack() {
    if(this.hit){return}
    
    if (this.currentQuestionID <= 1) {
      // do nothing.  you're on question one
      return 
    } else {
        document.getElementById(`${+this.currentQuestionID-1}`).classList.remove('hide');      
        this.back()
    }
    this.hit = true
    setTimeout(() => {
      this.hit = false
    }, 1000)
  }

  goForward() {
    if(this.hit){return}
    
    if ( this.currentQuestionID >= this.questions.length-1) {
      return
    } else {
      document.getElementById(`${this.currentQuestionID + 1}`).classList.remove('hide');
      this.forward()
    }
    this.hit = true
    setTimeout(() => {
      this.hit = false
    }, 1000)
  }

  goto(questionID: number) {
    if (this.currentQuestionID === questionID){
      return
    } else if (this.currentQuestionID > questionID){ 
      this.activate(questionID, 'hideLeft')
      this.deactivate(+this.currentQuestionID, 'hideRight')
      document.getElementById(this.currentQuestionID.toString()).classList.add('hide');
      document.getElementById(`${questionID}`).classList.remove('hide');  
    } else {
      this.activate(questionID, 'hideRight')
      this.deactivate(+this.currentQuestionID, 'hideLeft')
      document.getElementById(this.currentQuestionID.toString()).classList.add('hide');
      document.getElementById(`${questionID}`).classList.remove('hide');  
    }
    this.updateCurrentQuestion(questionID)
  }

  finishLesson(){
    this.um.finishLesson(+this.lessonID)
  }

  gotoLastUnansweredQuestion(){

    const keys = Object.keys(this.incomplete) 
    if(keys.length > 0){
      const lessonToGoTo = this.incomplete[`${keys[0]}`]
      this.goto(lessonToGoTo)
    }
  }

  finishCourse(){
    this.um.finishCourse()
  }
  
  download(activity: number){
    activity = 1
    new Blob(['text'], { type: 'application/pdf' });
    
  }


    }

