
import { Parent, Child } from './../../Models/user.interface';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../Services/UserManager/user-manager';
import { GlobalVariablesService } from '../../Services/globalVariables/global-variables.service';
import {transition, state, style, animate, trigger} from '@angular/animations'
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-login-sign-up',
  templateUrl: './login-sign-up.component.html',
  styleUrls: ['./login-sign-up.component.scss', '../../app.component.scss'],
  animations: [
    trigger('display',[
      state('void', style({
          opacity: 0,
          height: 0,
          display: 'none'
      })),
      state('show', style({
          opacity: 1
      })),
      transition('* => *', animate('200ms ease-in-out'))
    ]),
    trigger(
      'item', [
        transition(':enter', [
          style({height: 0, transform: 'scale(0)', opacity: 0}),
          animate('300ms', style({height: '*', transform: 'scale(1)', opacity: 1}))
        ]),
        transition(':leave', [
          style({height: '*', transform: 'scale(1)', opacity: 1}),
          animate('100ms', style({height: 0, transform: 'scale(0)', opacity: 0}))
        ])
      ])
  ]
})
export class LoginSignUpComponent implements OnInit {
firstname: string;
lastname: string;
password: string;
eemailConfirmation: string ;
passwordConfirmation: string ;
email: string;
signUpLabel = 'Sign Up';
loginLabel = 'Login';
errorView; 
lesson: number = 1;
parent = {} as Parent;
activeChild: Child;
loginOrSignUp;
forgotPassword = false;
leaving = new Subject<null>()
children =  Array<Child>();
addingChild = false;
ofAge: boolean = false;
childName: string;
  constructor( private router: Router,
    private um: UserManager,
    private gv: GlobalVariablesService,
    private afs: AngularFirestore) {
      this.um.getLoginDisplaySubject()
      .subscribe(loginOrSignUp => {
        if(this.parent && this.parent.anonymous && loginOrSignUp === 'select'){
          this.loginOrSignUp = 'signUp'
        } else {
          this.loginOrSignUp = loginOrSignUp
        }
        
      })
    }

  ngOnInit() {
    this.um.getProfileSubject().subscribe(parent => {

      this.parent = parent
      if(parent){
        this.afs.collection<Child>(
          'Children',
           ref => ref.where('parent', '==', this.parent.uid))
        .valueChanges()
        .takeUntil(this.leaving)
        .subscribe(children => {
          this.children = children
        })
      } else {
        this.children = []
      }
    })
    this.um.getActiveChild().subscribe(child => this.activeChild = child)
  }

  signUpWithEmailAndPassword() {
      if      (!this.firstname){ this.setError('First Name Required');  return}
      else if (!this.email)    { this.setError('Email Required');       return}
      else if (!this.lastname) { this.setError('Last Name Required');   return}
      else if (!this.password) { this.setError('Password Required');    return}
      else if (!this.ofAge){
        this.setError('You must confirm that you are at least 18 years old.\
          If you are not, please have your parent or guardian sign up for you.')
          return
      }
      else {
        if(this.parent && this.parent.anonymous){
          this.um.upgrade(
            this.email,
            this.password,
            this.firstname,
            this.lastname,
            this.eemailConfirmation,
            this.passwordConfirmation,
            this.ofAge
          )
          .then(_ => this.clearFields())
          .catch(e => this.setError(e.message))
        } else {
          this.um.signUpWithEmailAndPassword(
            this.email,
            this.password,
            this.firstname,
            this.lastname,
            this.eemailConfirmation,
            this.passwordConfirmation,
            this.ofAge)
          .then(_ => this.clearFields())
          .catch(e => this.setError(e.message))
        }

      }
    }

    clearFields(){
      this.firstname = ""
      this.lastname = ""
      this.email = ""
      this.password = ""
      this.eemailConfirmation = ""
      this.passwordConfirmation = ""
    }
    toSignUp(){
      this.errorView = null;
      this.um.openSignUpCloseLogin()
    }
    toLogin(){
      this.errorView = null;
      this.um.openLoginCloseSignUp()
    }
    toUserSelect(){
      this.errorView = null
      this.um.openUserSelect()
    }

    close(){
      this.errorView = null
      this.firstname = ""
      this.lastname = ""
      this.um.hideLoginContainer()
    }


    loginWithEmailAndPassword() {

      if (!this.email) {
        this.setError('Need an Email')
        return;
      }
      if (!this.password) {
        this.setError('Need an Password')
        return;
      }
      this.gv.print('REGULAR LOGIN CALLED')
      this.um.loginWithEmailAndPassword(this.email, this.password).then(message => {
        if( message){
          this.setError(message)
        }
      }).catch(e => this.setError(e))
    }
    anonymousLogin(){
      this.gv.print('ANONYMOUS LOGIN CALLED')
      // this.um.anonymousLogin().catch(e => this.setError(e.message))
  
      
    }
  
    resetPassword(){
      if(!this.email){
        this.errorView = "Please Enter an Email for us to send your password reset to."
        return
      }
      this.um.resetPassword(this.email)
      .then(success => this.errorView = success)
      .catch(e => {

        e.message && this.setError(e.message)
        !e.message && this.setError(e)

      })
    }
  
    setError(error){
      // console.log(error)
      this.errorView = error
      setTimeout(() => {
        this.errorView = null
      }, 5000)
    }
  // select child functions
  toggleAdd(){
    this.addingChild = !this.addingChild
  }
  addChild(){
    if(this.parent.anonymous){
      this.setError('You need to create an account before you can add your children')
      return 
    }
    if(!this.childName || this.childName === ''){this.setError('You must Enter a name'); return}
    this.um.addChild(this.childName)
    .then(success => {
      this.childName = null
      this.addingChild = false
      this.um.gotoMyLessons()
    })
    .catch(e => this.setError(e))
  }
  
  selectChild(child: Child){
    this.um.setActiveChild(child)
    .then(success => this.close())
    .catch(e => this.setError(e))
  }
  logout(){
    this.um.logout()
  }
}
