<div class="background">
  <div class="inset" [@trigger]=currentState>
    <h1>About the Bible Study Guides</h1>
    <p>Using this site is completely FREE.  There are 21 studies in the series covering major topics in the Bible.  Completing one study unlocks the next study in the series.  You can browse the 21 study titles here:
        </p>
  </div>
  
  <div class="inset">
    <app-lessons-flat></app-lessons-flat>
    <h1>How It Works</h1>
    <p>Nearly all of the questions in the studies are answered from the Bible directly. Most questions include the text of the Bible verse, with a couple missing words.
       Click a blank to select the correct word from the options presented. This feature is designed to help you get familiar with your own Bible,
       and also to help you think about the meaning and importance of the words in the verse. Once you’ve selected the missing words, the website
        will tell you immediately if your answers were right or wrong. When you get the correct answer you may advance to the next question.
      <br><br>At the end of each guide is a personal commitment question, to help you apply what you learned 
      in the guide to your own life. The guides were designed to be practical and down to earth.
        </p>

        <h1>Creating an Account</h1>
        <p>Creating an account allows you to keep track of your progress, sync your progress between devices, and continue where you left off. 
           Simply click the <span><strong class="bold">"Sign up"</strong></span> menu option to get started</p>

        <div class=center>
         <button class="lessonButton" (click)="gotoCurrentLesson()">Let's get started!</button>
        </div>
        <div class="container">
          <div>
            <p class="col1">These guides are based on the printed <span class="bold">My Place With Jesus Bible Guides</span>.
               To learn about these guides and related materials, please visit the<span ><a href="https://itiswritten.shop/product/my-place-with-jesus-bible-guide-set/" class="BoldText"> <u>It Is Written Store.</u></a></span>
            </p>
          </div>
          <a href="https://itiswritten.shop/product/my-place-with-jesus-bible-guide-set/">
            <img src="../../../assets/Images/mpwjbbsbox.png" class=bbg>
         </a>
        </div>
  </div>
</div>