import { Injectable , EventEmitter} from '@angular/core';
import { 
  PageScrollService,
  PageScrollOptions,
  PageScrollConfig, 
  PageScrollInstance } from 'ngx-page-scroll-core';




@Injectable()
export class ScrollService {
  easeInOut = (t: number, b: number, c: number, d: number): number => {
        // easeInOutExpo easing
        if (t === 0) return b;
        if (t === d) return b + c;
        if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
  };

  constructor( private pageScrollService: PageScrollService) {
    let config = {} as PageScrollConfig
    config.scrollOffset = 50;
    config.easingLogic = this.easeInOut


   }



   scrollToElement(element: string, emitter?: EventEmitter<boolean>){
     
    let el = document.getElementById(element.replace('#',''))
     if (el && emitter){
       let options = {
        document: document,
        scrollTarget:  element,
         pageScrollInterruptible: false,
         easingLogic: this.easeInOut,
        pageScrollFinishListener: emitter} as PageScrollOptions;
        const pageScrollInstance: PageScrollInstance = new PageScrollInstance(options);
        this.pageScrollService.start(pageScrollInstance);
     } else if (el) {
      let options = {
        document: document,
        scrollTarget: element,
        easingLogic: this.easeInOut,
        pageScrollInterruptible: false} as PageScrollOptions
        const pageScrollInstance: PageScrollInstance = new PageScrollInstance(options)
        this.pageScrollService.start(pageScrollInstance)
     }

   }
scrollVeriticalInContainer(container: string, element: string){
  let el = document.getElementById(element.replace('#',''))
  let contain = document.getElementById(container.replace('#',''))
  let options = {
      document: document,
      scrollTarget: element, 
      scrollViews: [contain],
      verticalScrolling: true,
      easingLogic: this.easeInOut
    } as PageScrollOptions
    let pageScrollInstance: PageScrollInstance = new PageScrollInstance(options);
    this.pageScrollService.start(pageScrollInstance);
    
}
 scrollHorizontalInContainer(container:string , element: string) {
   
  let el = document.getElementById(element.replace('#',''))
  let contain = document.getElementById(container.replace('#',''))
  let options = {
    document: document,
    scrollTarget: element, 
    scrollViews: [contain],
    easingLogic: this.easeInOut,
    verticalScrolling: false} as PageScrollOptions
    let pageScrollInstance: PageScrollInstance = new PageScrollInstance(options);
    this.pageScrollService.start(pageScrollInstance);
    
}

   

}
