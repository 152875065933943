import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManager } from '../../Services/UserManager/user-manager';
@Component({
  selector: 'app-bypass',
  templateUrl: './bypass.component.html',
  styleUrls: ['./bypass.component.css']
})
export class BypassComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private um: UserManager
  ) { 

  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    const id = this.route.snapshot.paramMap.get('id')
    if(+id > 25 || +id < 1){this.um.gotoHome(); return}
    setTimeout(() => this.um.bypassToLesson(+id), 1000)
  }
  

}
