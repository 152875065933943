
<div class="background">
    <div class="inset" >
      <h1>Using This Site</h1>
      <div class='horizontalLine'></div>
      <div class="skipContainer">
          <!-- <div class="button skipButton" (click)="goBack()">Back</div> -->
          <div class="button skipButton" (click)="gotoLesson()">Skip</div>
        </div>
      <div class='outerFlex'>

        <app-instruction [img]=instructions[index].img [text]=instructions[index].text ></app-instruction>
        <h3>{{index + 1}} / {{instructions.length}}</h3>
      </div>
      <div class="navigationButtonContainer">
        <div class="navButton regularText button" (click)="next()">Continue</div>
        <div *ngIf="index > 0" class="navButton regularText button" (click)="previous()">Go Back</div>
      </div>
    </div>
  </div>