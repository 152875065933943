<div class=darkArea (click)="onClose($event)"> 
  <div class=container (click)="doNothing($event)">
    <div class=closeFlex><h1 style="margin: 0px" (click)="onClose($event)">&times;</h1></div>
    <div class=avatar><h2>{{profile?.firstname | firstLetter}}{{profile?.lastname | firstLetter}}</h2>
      </div>
    <h4>{{profile?.firstname }} {{profile?.lastname}}</h4>
    <div *ngIf="profile?.lastUpdated" class=cflex>
      <h4 style="margin-bottom: 1px;">Last Active</h4> 
      <p style="margin-top: 0px">{{profile?.lastUpdated | date:'EEEE, MMMM d'}}</p>
    </div>
    <p>Current lesson: {{profile?.currentLesson}}</p>
    <div style="margin: 2px;"
        class=conf
        [ngClass]="{'true': c?.response === 'true', 
          'false': c.response !== 'true'}"
    *ngFor="let c of confirmations;" >{{cards[c?.index -1]?.topic}}</div>
  </div>
</div>