import { Component, OnInit } from '@angular/core';
import { trigger, state, transition, animate, style} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-finished-lesson',
  templateUrl: './finished-lesson.component.html',
  styleUrls: ['./finished-lesson.component.css', 'svg.scss'],
  animations: [
      trigger('item', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('400ms ease-in-out')]),
        transition('* => void',[ animate('400ms ease-in-out')]),
  ])]
})

export class FinishedLessonComponent implements OnInit {
  lesson; 
  constructor(private route: ActivatedRoute,) {
    this.lesson = this.route.snapshot.paramMap.get('lesson')
   }

  ngOnInit(): void {
  }

}
