<div id='signUpContainer' class='signUpContainer '>
	<div *ngIf="loginOrSignUp === 'signUp'" class='SignUpInnerContainer'>
		<h1 [@item] id='registerTitle' class='registerTitle'>{{signUpLabel}}</h1>
		<p  [@item] class="errorFont">{{errorView}}</p>
		<input [@item] id='firstname' class='signUpInputs' type='text' name='Fname' placeholder='First Name' [(ngModel)]="firstname"><br>
		<input [@item] id='lastname' class='signUpInputs' type='text' name='Lname' placeholder='Last Name' [(ngModel)]="lastname"><br>
		<input [@item] id='emailRegister'  class='signUpInputs' type='email' name='email' placeholder='email'[(ngModel)]="email"><br>
		<input [@item] id='passwordRegister' class='signUpInputs' type='password' name='pword' placeholder='password' [(ngModel)]="password"><br>
		<input [@item] id='emailConfirmation' type='email' name=emailConf class="confirmation"  [(ngModel)]=eemailConfirmation placeholder="Email Confirmation">
		<input [@item] id='passwordConfirmation' type='password' name=passwordConf class="confirmation"  [(ngModel)]=passwordConfirmation placeholder="Password Confirmation">
		<div>
			<input [@item] 
			type="checkbox"
			[(ngModel)]="ofAge"
			><span>I am at least 18 years old.</span>
		</div>
		<div [@item] class=buttonFlex>
		<div [@item] id='submitRegister' class=button (click)='signUpWithEmailAndPassword()'>Sign Up</div>
		<div [@item] id='cancelRegistration' class='button' (click)='close()'style='background-color: grey'>Cancel</div>
		</div>
	<p [@item] id='gotoLogin' class='icon textButton' (click)='toLogin()'>Go To Login</p>
		

	</div>


	<div *ngIf="loginOrSignUp === 'login'" class="SignUpInnerContainer">
		<h1  [@item] *ngIf="forgotPassword === false" id="loginTitle" class="registerTitle" style="position: relative; margin-top:10px;">{{loginLabel}}</h1><br>
		<p   [@item] id="loginErrorMessage" class="errorFont">{{errorView}}<p>
		<h1  [@item] *ngIf="forgotPassword === true" id="loginTitle" class="registerTitle" style="position: relative; margin-top:10px;">Forgot Password?</h1><br>
		<input id="emailLogin" class="signUpInputs" type="email" name="email" placeholder="email" [(ngModel)]="email"><br>
		<div [@item] *ngIf="forgotPassword === true" class=button (click)="resetPassword()" >Reset Password</div>
		<input 
		[@item] *ngIf="forgotPassword === false"
			id="passwordLogin"
			class="signUpInputs" 
			type="password" name="pword" placeholder="password" [(ngModel)]="password"><br>
		<p [@item] *ngIf="forgotPassword === true" class=icon (click)="forgotPassword = false">I know my password</p>
		<div class=buttonFlex>
			<div [@item] *ngIf="forgotPassword === false" id="submitLogin" class=button (click)="loginWithEmailAndPassword()">Login</div>
			<div [@item] *ngIf="forgotPassword === false" id="cancelLogin" class=button (click)="close()" style="background-color: grey" >Cancel</div>
		</div>
		<p [@item] *ngIf="forgotPassword === false" id="gotoSignUp" class="icon textButton"  (click)="toSignUp()">Sign Up</p>
		<p [@item] *ngIf="forgotPassword === false" class="icon textButton" (click)="forgotPassword = true">Forgot your password?</p>			
	</div>

	<div class="profileContainer">
	 <div  *ngIf="loginOrSignUp === 'select'" class="SignUpInnerContainer">
		 <!-- children and add child button -->
		 <h1 [@item] class='registerTitle'>
			 <span *ngIf="addingChild">Create Child</span>
			 <span *ngIf="!addingChild">Choose</span>
			  Profile
		</h1>
		 <p  [@item] class="errorFont">{{errorView}}</p>
		 <div class=vflex >
				<div class="addChild button2" (click)="selectChild(null)">
				<h1>{{parent?.firstname | firstLetter}}{{parent?.lastname | firstLetter}}</h1>
				</div>
				<h4 style="text-align: center;">{{parent?.firstname | cap}} {{parent?.lastname | cap}}</h4>
			</div>
		<div [@item] *ngIf="!addingChild" class=children>
			<div *ngFor="let child of children" class=vflex>
				<div 
				[ngStyle]="{'background-color': child.color}" 
				class="child button2"
				(click)="selectChild(child)" >
					<h1>{{child?.name | firstLetter}}</h1>
				</div>
				<h4>{{child?.name | cap}}</h4>
			</div>
			<div class=vflex (click)="toggleAdd()">
				<div class="addChild button2">
					<h1>&#43;</h1>
				</div>
				<h4>Add Child</h4>
			</div>
		</div>
		<input [@item] *ngIf="addingChild"
		class='signUpInputs'
		type='text'
		name='cname'
		placeholder='Nick Name'
		[(ngModel)]="childName"><br>
		
	<!-- Cancel and back buttons -->
	<div class=childButtonFlex>
		<div [@item] *ngIf="addingChild"  class="button childButton" style="background-color: #71BEC7" (click)="addChild()">Submit</div>
		<div [@item] *ngIf="!addingChild"  class="button childButton" (click)="close()" style="background-color: grey" >Cancel</div>	
		<div [@item] *ngIf="addingChild"  class="button childButton" (click)="toggleAdd()" style="background-color: grey" >Back</div>
		<div [@item] *ngIf="!addingChild"  class="button childButton" (click)="logout()">Logout</div>
	</div>
	</div>
</div>
