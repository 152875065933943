<div *ngIf="allState" class="titleBarBackground"  >
  <div class="background" [@backgroundTrigger]="backgroundState"  >
    <div class="inset" >
        <div class="titleBarText">
         <br>
         <h4 class=" titleBarText accentText" >Guide {{lessonCard?.id}}</h4>
         <h1 class="accentText" >{{lessonCard?.title}}</h1>
       </div>
   </div>
  </div>
   <img data-src={{lessonCard?.img}} class="titleImage" [@imageTrigger]="imageState">
 </div>