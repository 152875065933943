import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../Services/UserManager/user-manager';
import { Parent } from './../../Models/user.interface';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-finished',
  templateUrl: './finished.component.html',
  styleUrls: ['./finished.component.css']
})
export class FinishedComponent implements OnInit {
  profileSubject:  Observable<Parent>;
  profileSubscription: Subscription;
  profile = {} as Parent;
  firstname;
  lastname;
  // blankCertificate ='/Users/jfluence/Documents/IIWProjects/studiesv2/src/assets/Images/certificate.png'
  blankCertificate ='/assets/Images/cover1.jpg'

  constructor(private um: UserManager) { }

  ngOnInit() {
    this.profileSubject = this.um.getProfileSubject()
    this.profileSubscription = this.profileSubject.subscribe(profile => {
      if(profile){
        if(profile.firstname ){
          this.firstname = profile.firstname
        }
        if(profile.lastname){
          this.lastname = profile.lastname
        }
      }
    })
  }
  gotoMyLessons(){
    this.um.gotoMyLessons()
  }
  gotoContactUs(){
    this.um.gotoContactUs()
  }


}
