

<div class="headerStyle">
        <div class="outerFlex" >
            <!-- <div id="logo" class=nlogo></div> -->
            <a id=logo class=logoCase href="https://myplacewithjesus.com">

                <img class=logo src="../../../assets/Images/mpwj_logo.png">
                
                <h2>Bible Guides</h2>
            </a>
            
            <div  class="flexContainer" >
                <div *ngIf="parent || activeChild" id="myLessonsButton" class="iContainer " (click)="gotoMyLessons()">
                    <!-- <img src="../../../assets/icons/myGuides.svg" class="icon"/> -->
                    <div  class="navbutton " >{{Me}}</div>
                </div>
                <div class="iContainer" (click)="gotoGames()">
                        <!-- <img src="../../../assets/icons/contactUs.svg" class="icon"/> -->
                        <div class="navbutton" >Puzzles</div>
                    </div>
                <div  *ngIf="parent && !parent?.anonymous || activeChild " id="LogoutHeaderButton" class="iContainer" (click)="logout()">
                    <img *ngIf="!activeChild && !parent" src="../../../assets/icons/loginLogout.svg" class="icon"/>
                    <div class="avatar">
                        <div 
                        *ngIf="activeChild"
                        [ngStyle]="{'background-color': activeChild.color}" 
                        class="activeChild button2"
                        >
                        <h1>{{activeChild?.name | firstLetter}}</h1>
                        <p [ngStyle]="{'color': activeChild.color}">{{activeChild?.name | cap}}</p>
                        </div>
                        <div 
                        *ngIf="!activeChild && parent"
                        [ngStyle]="{'background-color': 'grey'}" 
                        class="activeChild button2"
                        >
                        <h1 *ngIf="!parent?.anonymous">{{parent?.firstname | firstLetter}}</h1>
                        <p *ngIf="!parent?.anonymous">{{parent?.firstname | cap}}</p>
                        </div>
                    </div>
                    <div  class="navbutton avaHide" >Profile</div>
                </div>  

                </div>
        </div>
</div>


  
  
  