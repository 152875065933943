<div *ngIf="lessonCards"class="flex"  [@all]="currentState" >
    <div *ngFor="let lesson of lessonCards | async; let i = index "  class = "lessonContainer" >
            <img src={{lesson.img}} class="lessonPhoto" (click)="gotoLessonHeader(lesson)"       
            [@guideAnimation]="guideTriggers[i]" 
            (mouseenter)="setMouse('hover', lesson)"
            (mousedown)="setMouse('press', lesson)" 
            (mouseleave)="setMouse('rest', lesson)" 
            (@mouse.done)="animateEnd($event)"
            [@mouse]="mouseTriggers[i]">
            
            <div *ngIf="!lesson.unlocked" class="cover "><span class="coverText darkGreyText regularText">LOCKED</span></div>
            <div class="greyText lessonIndex captionText center">Guide {{lesson.id}}</div>
            <div class="darkGreyText lessonTitle regularText">{{lesson.title}}</div>
    </div>
</div>