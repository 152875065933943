import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../Services/UserManager/user-manager';

@Component({
  selector: 'app-main-top',
  templateUrl: './main-top.component.html',
  styleUrls: ['./main-top.component.scss']
})
export class MainTopComponent implements OnInit {

  constructor(private um: UserManager) { }

  ngOnInit() {
  }
  start(){
    this.um.gotoLessonHeader(1)
  }
}
