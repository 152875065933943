import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crossword-bottom-background',
  templateUrl: './crossword-bottom-background.component.html',
  styleUrls: ['./crossword-bottom-background.component.css']
})
export class CrosswordBottomBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
