
<div id="footer-container">
  <footer class="footer">
    <div class="center">
      <img src="/assets/Images/IIWLogoWhite.png" >
      <!-- <div class="footer-logo-text">An <strong>It Is Written</strong> Ministry</div> -->
    </div>
    <div class="footer-copywrite">
      <p>Copyright © 2008-2018, It Is Written,
         Inc. All rights reserved. It Is Written 
         and My Place With Jesus are registered
          trademarks and/or service marks of It Is Written, Inc.  V1.0</p>
    </div>
  </footer>

  </div>
  