
<div>
<kb-page-slider [overlayIndicator]="true" dotColor="black" [(page)]="index" style="width: 100%;padding-bottom: 30%;">
  <div *kbPages="let slide of slides"  >
    <a href={{slide.link}}>
      <img src={{slide.image}} style="width: 100%"/>
  </a>
  </div>
  <!-- <kb-nav-button backward [showBackground]="false" 
  [(page)]="index" [pageCount]="slides.length">
  </kb-nav-button>
  
  <kb-nav-button forward [showBackground]="false"
  [(page)]="index" [pageCount]="slides.length">
  </kb-nav-button> -->
</kb-page-slider>


</div>