import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../Services/UserManager/user-manager';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import {trigger, state, transition, animate, style} from '@angular/animations'
@Component({
  selector: 'app-lessons-information',
  templateUrl: './lessons-information.component.html',
  styleUrls: ['./lessons-information.component.scss'],
  animations: [
    trigger('trigger', [
        state('void', style({  opacity: 0 })),
        state('show', style({  opacity: 1 })),
        transition('void => *',[ animate('400ms ease-in-out')]),
        transition('* => void',[ animate('400ms ease-in-out')]),
  ])]
})
export class LessonsInformationComponent implements OnInit {
  currentState = 'void'
  constructor(private um: UserManager, private scroll: ScrollService) { }

  ngOnInit() {
    this.scroll.scrollToElement('#logo')
    this.currentState = 'show'    
    
  }

  gotoCurrentLesson() {
      if (this.um.isLoggedIn()) {
        this.um.gotoLessonHeader();
      } else {
        this.um.gotoLessonHeader(1);
      }
    }
}
