import { VersePrompt } from './../../Models/prompt.interface';
import { Component, OnInit, Input, Output, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AngularFireDatabase, } from '@angular/fire/database';
import { Question } from '../../Models/question.interface';
import { Subscription } from 'rxjs/Subscription';
import { Answer} from '../../Models/user.interface'
import { UserManager } from '../../Services/UserManager/user-manager';
import { EventEmitter } from '@angular/core';
import { GlobalVariablesService } from '../../Services/globalVariables/global-variables.service';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { Observable } from 'rxjs/Observable';
import { ScrollService } from '../../Services/ScrollManager/scroll.service';
import { trigger, state, style, transition, animate, keyframes} from '@angular/animations';
import { Message } from '../../Models/conversation.interface';
import { Supplement } from '../../Models/supplemental.interface';
import { Subject } from 'rxjs/Subject';
import { SelectedVerse } from '../../Models/selectedVerse.interface';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-question-template',
  templateUrl: './question-template.component.html',
  styleUrls: ['./question-template.component.scss','./svg.scss','dragula.min.css'],
  animations: [
    trigger('questionTrig', [
        state('show', style({
            opacity: 1,
            transform: 'translateX(0%)'
        })),
        state('void', style({
          transform: 'translateX(0%)',
          opacity: 0
        })),
        state('hideLeft', style({
          opacity: 0,
          transform: 'translateX(-100%)'
       })),
        state('hideRight', style({
          opacity: 0,
          transform: 'translateX(100%)'
          })),
          state('supplement', style({
            transform: 'translateY(-100%)',
          opacity: 0,
          height: 0,
          // display: 'none'
        })),
        transition('* => supplement', animate('300ms  ease-in-out')),
        transition('supplement => *', animate('300ms  ease-in-out')),
        transition('void => show', animate('400ms  ease-out', style({opacity: 1, transform: 'translateX(0%)'}))),
        transition('show => void', animate('400ms  ease-out', style({opacity: 1, transform: 'translateX(0%)'}))),

        transition('hideLeft => show', animate('400ms  ease-out', style({opacity: 1, transform: 'translateX(0%)'}))),
        transition('hideRight => show', animate('400ms  ease-out',style({opacity: 1, transform: 'translateX(0%)'}))),
        transition('show => hideLeft', animate('400ms 400ms ease-out', style({opacity: 0, transform: 'translateX(-100%)'}))),
        transition('show => hideRight', animate('400ms 400ms ease-out',style({opacity: 0, transform: 'translateX(100%)'}))),

      ]),
      trigger('userQuestion', [
          state('show', style({
            opacity: 1,
            transform: 'translateY(0%)'
          })),
          state('void', style({
            transform: 'translateY(50%)',
            opacity: 0
          })),
          transition('void => show', animate('300ms  ease-out', style({opacity: 1, transform: 'translateY(0%)'}))),
          transition('show => void', animate('300ms  ease-out', style({opacity: 0, transform: 'translateY(50%)'}))),
      ]),
      trigger('supplementTrigger', [
          state('supplement', style({
            opacity: 1,
            transform: 'translateY(0%)',
            height: '*'
          })),
          state('void', style({
            opacity: 0,
            transform: 'translateY(50%)',
            height: 0
          })),
          transition('* => supplement', animate('300ms  ease-in-out')),
          transition('supplement => *', animate('300ms  ease-in-out'))
    ]),
    trigger(
      'item', [
        transition(':enter', [
          style({ opacity: 0}),
          animate('300ms', style({ opacity: 1}))
        ]),
        transition(':leave', [
          style({ opacity: 1}),
          animate('100ms', style({ opacity: 0}))
        ])
      ])
  ]
})


export class QuestionTemplateComponent implements OnInit , OnChanges, OnDestroy{

@Output() complete: EventEmitter<number> = new EventEmitter();
@Output() incomplete: EventEmitter<number> = new EventEmitter();

  @Input() lessonID: string;
  @Input() questionID: string;
  showQuestion: boolean = false;
  vpShown: boolean = false;
  allCorrect: boolean = false;
  currentState = "void";
  supplementState = "void"
  selectedSupplement: Supplement;
  supplements;
  questionSubscription: Subscription;
  question$;
  question = {} as Question;
  leaving = new Subject<null>()  // emitted when a null user profile comes in...triggered by logout

  versePromptsSubscription: Subscription;
  versePrompts$:  Observable<VersePrompt[]>;
  versePrompts = Array<VersePrompt>();
  versePromptIDs = Array<string>();
  bodyID: string;
  shouldScrollToBody: boolean = false;
  selectedAnswer: Answer; 
  selectedElement;
  lastClass;
  deleteTimerSubscription: Subscription;            
  deleteTimer: Observable<number>;
  radioDisplayAnswer;
  dropSubscription;
  dragSubscription;
  dragEndSubscription;

  selectedVerse = {} as SelectedVerse;

  questionUserEmail;
  questionMessage;
  questionError;
  userQuestion = 'void';
  profile;
  churchMessage ;
  
  // DOM variables
  versesContainer = document.getElementById('verseModal')
  
  showVerse: boolean = false;
  

  displayAnswers = {};
  answers = {};
  answersArray = Array<any>()
  answerSubscriptions = {};


  scrollTimer;

  constructor(private db: AngularFireDatabase,
              private um: UserManager,
              private gv: GlobalVariablesService,
              private scroll: ScrollService ) {
                this.churchMessage = "I'd like to find a church near me to visit.  Can you help me find one?"
  }
      //(0 - bagname, 1 - el, 2 - target, 3 - source, 4 - sibling)
  

  onDrop(event: CdkDragDrop<string[]>, id: string) {   
    // console.log(event, id)   
      if (event.previousContainer === event.container) {
        this.gv.print('same container')
        // this.gv.print(event.container, event.previousContainer)
        moveItemInArray(this.question.choices, event.previousIndex, event.currentIndex);
      } else {
        this.gv.print('different container')
        // get id from last container

        this.gv.print(id, event)
        let answer = {} as Answer;
        answer.choice = event.item.data
        let rawID = id
        let splits = rawID.split("-")
        let _id = splits[splits.length - 1]
        answer.blankID = this.versePrompts[+_id].$key
        this.saveAnswer(answer)
        
      }
  }

  ngOnChanges(changes: SimpleChanges){
    // console.log(changes)
    
  }

  ngOnInit() {
    this.um.getProfileSubject()
    .subscribe(profile => {
      if(profile){
        this.profile = profile
        this.questionUserEmail = this.profile.email || ""
      } else {
        this.leaving.next(null) // kill all the subscriptions on logout
      }
    })
    
    
  }

  getKeys(object){
    // get the keys from an object and remove the $value key
    var keys = Object.keys(object)
    const index = keys.indexOf('$value')
    if (index > -1) {keys.splice(index, 1)}
    return keys
  }

  selectElement(element){
    if(this.selectedElement){this.deSelectElement()}
    //check the classes the element has
    

    element.classList.add("selected")
    element.classList.remove('unselected')
    this.selectedElement = element
    
  }

  deSelectElement(){
    
    if(!this.selectedElement){return}
    this.selectedElement.classList.remove("selected")
    this.selectedElement.classList.add("unselected")
    this.selectedElement = null

  }
  selectChoice(choice: string, event){
     
    event.stopPropagation()
    var answer = {} as Answer;
    answer.choice = choice
    this.selectedAnswer = answer
    this.deSelectElement()
    this.selectElement(event.target)
  }

  deselectAnswer(){
    this.selectedAnswer = null
  }
  selectAnswer(answer: Answer, event, force = null){ 
    event.stopPropagation()
    // console.log(answer)
    if(force){
      // remove the answer that was clicked 
      this.removeAnswer(answer)
      return
    }
    if(this.selectedAnswer && this.deleteTimer){
      if (this.answers[answer.blankID]){
          // const vp = this.versePrompts[answer.blankID]
          this.removeAnswer(this.selectedAnswer)
      }
      this.selectedAnswer = null
      this.deSelectElement()

      this.clearTimer()
      return
    }
    answer.choice = answer.choice.replace(/\s/g, '')
    // console.log(`${answer.choice}`)
    this.selectedAnswer = answer
    this.selectElement(event.target)
    if(!this.deleteTimer){
     this.deleteTimer = IntervalObservable.create(500)
     this.deleteTimerSubscription = this.deleteTimer.subscribe(() =>  this.clearTimer() )
    }

  }
  clearTimer() {
    this.deleteTimerSubscription.unsubscribe()
    // this.deleteTimerSubscription = null
    // console.log(`sub = ${this.deleteTimerSubscription}`)

    this.deleteTimer = null
  }

  selectBlank(vp: VersePrompt){
    // console.log('Blank Target!!')
    if (!this.selectedAnswer){return}

    if (this.selectedAnswer.blankID){
      if (this.selectedAnswer.blankID !== vp.$key){this.removeAnswer(this.selectedAnswer)}
    }

    this.selectedAnswer.blankID = vp.$key
    this.saveAnswer(this.selectedAnswer)
    .then(_ =>  {this.selectedAnswer = null; this.deSelectElement()})
    .catch(e => { console.error(e);  this.selectedAnswer = null;this.deSelectElement()})

  }

  selectVerse(ref, body){

    if(ref && body && this.selectedVerse){
        this.selectedVerse['ref'] = ref
        this.selectedVerse['body'] = body
        this.setVerseShown(true)
    }
  }
  
  shouldShowChoice(choice: string): boolean{
   
    var keys = this.getKeys(this.answers)
    if (keys.length <= 0 || !keys) { return false}
    for ( let key of keys){ if (this.answers[key] && choice == this.answers[key].choice){return false}}
    return true


  }

  toggleOnOff(){
    this._toggleOnOff()
  }
  _toggleOnOff(){
    Promise.all([promise1, promise2]).then(_ => this.deactivate())
    var promise1 = new Promise((resolve) =>{
            this.question$ = this.db.object<Question>(`Questions/${this.lessonID}/${this.lessonID}-${this.questionID}`)
            .valueChanges()
            .take(1)
            .subscribe(question => { 
              this.question = question;
              this.bodyID = this.createBodyID(this.lessonID, this.questionID)
              

              if (this.question.questionType === 'truthConfirmation'){
                // do a look up and see if the user has answered
                this.um.getAnswersSubscription(+this.lessonID, +this.questionID, 0)
                .then(truthConfirmationSubscription => {
                  if(truthConfirmationSubscription){
                    truthConfirmationSubscription.take(1).subscribe(answer => {
                      this.answers["0"] = answer;
                      this.checkIfAllCorrect()
                      // this.gv.print(this.versePrompts) 
                      this.showQuestion = true
                      resolve()
                     })
                    }
                 })
                 .catch(e => resolve())
              } else if (this.question.questionType === 'text'){
                  this.allCorrect = true;
                  this.complete.emit(+this.questionID);
                  // this.gv.print(this.versePrompts) 
                  this.showQuestion = true
                  resolve()
              } else {
                  // this.gv.print(this.versePrompts) 
                  this.showQuestion = true
                  resolve()
              }


            });  
          })
    var promise2 = new Promise((resolve) => {
        this.versePrompts = Array<VersePrompt>()
        var v= this.db.list<VersePrompt>(`Questions/${this.lessonID}/${this.lessonID}-${this.questionID}/blanks`)
        .snapshotChanges()
        .take(1)
        .subscribe(actions => {
          // console.log(actions)
          if(actions){ 
            var total = 0
            if(actions.length === 0){
              const key = "0"
              this.um.getAnswersSubscription(+this.lessonID, +this.questionID, +key)
              .then(blankSubscription => {
              
                this.answerSubscriptions[key] = blankSubscription
                .takeUntil(this.leaving)
                .subscribe(answer => {
                  this.answers[key] = answer;
                  
                    if(this.answers[key]){
                      this.radioDisplayAnswer = this.answers[key].choice
                    }
                  this.checkIfAllCorrect();  
                })
              }).then(_ => {
                  this.gv.print(this.answers); 
                  total += 1
                  if(total === actions.length){
                    resolve()
                  }
                })
              resolve();
              return;
            } else {
              actions.forEach(action => {
                let key = action.key
                let val = action.payload.val() as VersePrompt
                val.$key = key
                
                this.versePrompts[key] = val
                this.versePromptIDs.push(this.createBlankID(this.lessonID, this.questionID, +key) ) 
                this.gv.print([...this.versePromptIDs])
                this.um.getAnswersSubscription(+this.lessonID, +this.questionID, +key)
                .then(blankSubscription => {
                
                  this.answerSubscriptions[key] = blankSubscription
                  .takeUntil(this.leaving)
                  .subscribe(answer => {
                    this.answers[key] = answer;
                    if(key === "0"){
                      if(this.answers[key]){
                        this.radioDisplayAnswer = this.answers[key].choice
                      }
                    }
                    this.checkIfAllCorrect();  
                  })
                }).then(_ => {
                    this.gv.print(this.answers); 
                    total += 1
                    if(total === actions.length){
                      resolve()
                    }
                  })
              })
            }
          }
        })
      })
  }

  activate(fromDirection?: string):Promise<any> {
    var promise1 = new Promise((resolve) =>{
          this.question$ = this.db.object<Question>(`Questions/${this.lessonID}/${this.lessonID}-${this.questionID}`)
          .valueChanges()
          .take(1)
          .subscribe(question => { 
            this.question = question;
            // this.scrollToQuestion()
            this.bodyID = this.createBodyID(this.lessonID, this.questionID)
            

            if (this.question.questionType === 'truthConfirmation'){
              // do a look up and see if the user has answered
              this.um.getAnswersSubscription(+this.lessonID, +this.questionID, 0)
              .then(truthConfirmationSubscription => {
                if(truthConfirmationSubscription){
                  truthConfirmationSubscription
                  .take(1)
                  .subscribe(answer => {
                    this.answers["0"] = answer;
                    this.checkIfAllCorrect()
                    // this.gv.print(this.versePrompts) 
                    this.showQuestion = true
                    resolve()
                   })
                  }
               })
               .catch(e => resolve())
              } else if (this.question.questionType === 'text'){
                this.allCorrect = true;
                this.complete.emit(+this.questionID);
                // this.gv.print(this.versePrompts) 
                this.showQuestion = true
                resolve()
              } else {
                // this.gv.print(this.versePrompts) 
                this.checkIfAllCorrect()
                this.showQuestion = true
                resolve()
              }


          });  
        })
    var promise2 = new Promise((resolve) => {
          this.versePrompts = Array<VersePrompt>()
          var v= this.db.list<VersePrompt>(`Questions/${this.lessonID}/${this.lessonID}-${this.questionID}/blanks`)
          .snapshotChanges()
          .take(1)
          .subscribe(actions => {
            // console.log(actions)
            if(actions){ 
              var total = 0
              if(actions.length === 0){
                const key = "0"
                this.um.getAnswersSubscription(+this.lessonID, +this.questionID, +key)
                .then(blankSubscription => {
                
                  this.answerSubscriptions[key] = blankSubscription
                  .takeUntil(this.leaving)
                  .subscribe(answer => {
                    this.answers[key] = answer;
                    
                      if(this.answers[key]){
                        this.radioDisplayAnswer = this.answers[key].choice
                      }
                    this.checkIfAllCorrect();  
                  })
                }).then(_ => {
                    this.gv.print(this.answers); 
                    total += 1
                    if(total === actions.length){
                      resolve()
                    }
                  })
                resolve();
                return;
              } else {
                actions.forEach(action => {
                  let key = action.key
                  let val = action.payload.val() as VersePrompt
                  val.$key = key
                  
                  this.versePrompts[key] = val
                  this.versePromptIDs.push(this.createBlankID(this.lessonID, this.questionID, +key) ) 
                  this.gv.print([...this.versePromptIDs])
                  this.um.getAnswersSubscription(+this.lessonID, +this.questionID, +key)
                  .then(blankSubscription => {
                  
                    this.answerSubscriptions[key] = blankSubscription
                    .takeUntil(this.leaving)
                    .subscribe(answer => {
                      this.answers[key] = answer;
                      if(key === "0"){
                        if(this.answers[key]){
                          this.radioDisplayAnswer = this.answers[key].choice
                        }
                      }
                      this.checkIfAllCorrect();  
                    })
                  }).then(_ => {
                      this.gv.print(this.answers); 
                      total += 1
                      if(total === actions.length){
                        resolve()
                      }
                    })
                })
              }
            }
          })
    })
 // if we dont do Promise.all, for some reason the dragula subscription doesn't quite take correctly,
 // and we end not being able to drop dragged items in the different blanks when they originate from the word cloud.
    return new Promise((resolve, reject) => {
      Promise.all([promise1, promise2]).then(_ =>{
        // this.dropSubscription =  this.dragulaService.drop().subscribe((value) => {
        //   this.onDrop(value);
        // });
        // this.dragSubscription =  this.dragulaService.drag().subscribe((value) => {
        //   this.onDrag(value);
        // });
        // this.dragEndSubscription = this.dragulaService.dragend().subscribe((value) => {
        //   this.onDragEnd(value);
        // });
        this.supplements = this.db.list(`Questions/${this.lessonID}/${this.lessonID}-${this.questionID}/Supplements`).valueChanges();
        this.vpShown = true
        this.currentState = 'show'
        resolve()
        
      }).catch(e => resolve())
    })
  }

  createBlankID(lessonID: string, questionID: string, blankid: number) : string {
    return `${lessonID}-${questionID}-${blankid}`
  }
  createBodyID(lessonID: string, questionID: string) {
    return `${lessonID}-${questionID}-body`
  }

  ngOnDestroy(){
    this.deactivate()
  }
  deactivate(toDirection?: string){
      //  if(toDirection) {this.currentState = toDirection }
      //  else {this.currentState = 'void'}

   
    // console.log(this.getKeys(this.answerSubscriptions))
    this.getKeys(this.answerSubscriptions).forEach(key =>{
      // console.log(`unsubscribing from ${key}`)
      this.answerSubscriptions[key].unsubscribe()
    })

    if ( this.deleteTimerSubscription){
      this.deleteTimerSubscription.unsubscribe()
    }

    this.vpShown = false;
    if (this.dropSubscription) {
      // console.log(`Drop unsubscribe question ${this.questionID}`)
      this.dropSubscription.unsubscribe();
    }
    if (this.dragSubscription) {
      this.dragSubscription.unsubscribe();
    }
    if (this.dragEndSubscription) {
      // console.log(`Dragend unsubscribe question ${this.questionID}`)
      this.dragEndSubscription.unsubscribe();
    }

    if(this.questionSubscription) {
      this.questionSubscription.unsubscribe();
    }

    if(this.versePromptsSubscription) {
      this.versePromptsSubscription.unsubscribe();
    }




    this.displayAnswers = {};
    this.showQuestion = false
    this.currentState = 'void'
    this.supplementState = 'void'
  }

  answerExists(id: string){
     return this.answers[id] ? true : false
  }

  removeAnswer(answer: Answer){
    if (this.answers[answer.blankID]){
      // console.log('called remove')
      this.um.removeAnswer(answer, this.lessonID, this.questionID)
      .then(_ => this.checkIfAllCorrect())
      .catch(e => console.error(e))
      this.question.choices = this.question.choices.filter(choice =>  choice !== answer.choice && choice)
      this.question.choices.push(answer.choice)
    
    }

  }
  

  saveAnswer(answer: Answer):Promise<any>{
    if(!answer){
      return Promise.resolve()
    }
    var vp = this.versePrompts[answer.blankID]
    this.shouldScrollToBody = false
    answer.correct = vp.correctAnswer === answer.choice ? true : false;
    return new Promise((resolve, reject) => {
         this.um.saveAnswer(answer, this.lessonID, this.questionID)
         .then( _ => {resolve(); this.checkIfAllCorrect()} )
         .catch(e => {reject(e); this.checkIfAllCorrect()})
         
      })
  }

    truthConfirmed(choice: string){
      this.allCorrect = true // set the confirmation as true but save the actual answer.  if false, open the "have a question dialog"
      this.gv.print("YOU HAVE NOT IMPLEMENTED THE OPEN QUESTION DIALOG YET.  DO THIS!")
      var answer = {} as Answer;
      answer.choice = choice;
      answer.correct =  true // this.question.correctAnswer === choice ? true : false
      answer.blankID = "0"
      this.answers["0"] = answer
      this.um.saveAnswer(answer, this.lessonID, this.questionID);
      this.checkIfAllCorrect();
    }

    saveAnswerWithIndex(choice: string) {
      const vp = this.versePrompts[0];
      var answer = {} as Answer;
      // console.log(answer.choice)
      answer.choice = choice;
      answer.blankID = vp.$key
      answer.correct = vp.correctAnswer === choice ? true : false
      this.um.saveAnswer(answer, this.lessonID, this.questionID)
      this.shouldScrollToBody = false
      // this.answers[vp.$key] = answer
      this.radioDisplayAnswer = choice
      this.checkIfAllCorrect();
    }

    async saveTrueOrFalse(choice: string){
      console.log(choice)
      var answer = {} as Answer;
      answer.choice = choice;
      answer.correct = this.question.correctAnswer === choice ? true : false
      answer.blankID = "0"
      this.shouldScrollToBody = false
      await this.um.saveAnswer(answer, this.lessonID, this.questionID);
      this.checkIfAllCorrect();
    }
    
    checkIfAllCorrect(){
      var answerKeys = this.getKeys(this.answers)
      if (this.question.questionType === 'trueOrFalse' || this.question.questionType === 'radio' || this.question.questionType === 'truthConfirmation'){
        this.gradeOthers(answerKeys)
      } else if (this.question.questionType === 'wordCloud'){
        this.gradeWordCloud(answerKeys)
      }
    }

    gradeOthers(answerKeys: string[]){
        // should only be one key for true or false questions
        if (answerKeys.length === 1){
          for (let key of answerKeys){
            
            var answer: Answer = this.answers[key]
            if(!answer){this.markIncorrect(); return}
            if (!answer.correct){this.markIncorrect(); return  }
          } 
          this.markCorrect()
        } else { // no answer, by default incorrect
          this.gv.print("no answers, so false by default")
          this.markIncorrect()
        }
    }

    gradeWordCloud(answerKeys: string[]){
      if (answerKeys.length === this.versePrompts.length  && this.versePrompts.length != 0){  
        for(let key of answerKeys){
          var answer: Answer = this.answers[key]
          // console.log(`answer before check  = ${answer}`)
          if (answer){
              if (!answer.correct) {
                this.markIncorrect()
                  return 
              }
          } else {
            this.markIncorrect()
            return 
          }
        }
          this.markCorrect()
      } else {
        this.markIncorrect()
      }
    }

    markCorrect(){

      this.allCorrect = true
      this.complete.emit(+this.questionID)
      const body = document.getElementById(`${this.lessonID}-${this.questionID}-body`)

      if (body) {
        //  this.scroll.scrollToElement(`#${this.lessonID}-${this.questionID}-body`)
          body.classList.add('fade');
          body.classList.add('show'); }
    }
    markIncorrect(){
      // console.log('incorrect')
      this.allCorrect = false
      const body = document.getElementById(`${this.lessonID}-${this.questionID}-body`)
      if (body) {  body.classList.remove('show')}
      this.incomplete.emit(+this.questionID)
      
    }

    scrollToQuestion(){
      // document.getElementById(this.questionID).scrollIntoView();
    }

    setVerseShown(bool: boolean) {
      // this.gv.print(this.versesContainer.classList)
      this.showVerse = bool; 
    }


    closeModal(event){
      // console.log(event.target.id)
      this.gv.print(event.target)
      if (event.target.id === 'verseModal') {
        this.showVerse  = false;
        this.selectedVerse = {} as SelectedVerse
      }
      
    }
    stopPropagation(event){
      // console.log('stoping')
      event.stopPropagation()
    }

    // supplement code
    openSupplement(supplement: Supplement){
      this.selectedSupplement = supplement
      
      this.currentState = 'supplement'
      let element = '#lessonTopAnchor'
      this.scrollTimer =  setTimeout(() => {
        this.scrollAndClear(element)
      }, 500)
    }

    openSupplementEnd(e){
      if(!this.showQuestion){return}
      if(e.toState === 'supplement' && e.fromState === 'show'){
        this.supplementState = 'supplement'
      }
    }

    closeSupplement(event){
      event.stopPropagation()
      this.supplementState = 'void'
    }

    closeSupplementEnd(e){
      if(!this.showQuestion){return}
      if(e.toState === 'void' && e.fromState ==='supplement'){
         this.currentState = 'show'
         let element = '#lessonTopAnchor'
        //  this.selectedSupplement = null
        this.scrollTimer =  setTimeout(() => {
          this.scrollAndClear(element)
        }, 500)
      }
    }
    scrollAndClear(element){
      this.scroll.scrollToElement(element)
      clearInterval(this.scrollTimer)
    }
}

